import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CloseButton } from "react-bootstrap";
import { useWalletConnection } from "../../utility/useWalletConnection";
import { useData } from "../../utility/useData";
import { decodeBytes32String, formatEther } from "ethers";

const UserTickets = ({ contractGetInfo, userTickets, setUserTickets }) => {
  const { search } = useLocation();
  const { isConnected, connectedWallet, address, signerVars, unSignerVars } =
    useWalletConnection();
  const {
    decimals,
    initalResultMsg,
    showPoolDetail,
    poolData,
    setReRender,
    togglePoolData,
    openData,
    closeData,
    updatePoolData,
    statusText,
  } = useData();

  const [lpwc, setLpwc] = useState(0);
  const [isRevealedTickets, setIsRevealedTickets] = useState(false);
  const [prizes, setPrizes] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getTicketDetails = async () => {
    setIsLoading(true);
    const prizeSeedsCount = Number(
      (await signerVars.contract.getInfo(poolData.poolId, address))
        .prizeSeedsCount
    );
    setIsRevealedTickets(prizeSeedsCount > 0 ? true : false);

    const lpwc = await signerVars.contract.lastPrizeWinnerCount(
      poolData.poolId
    );
    console.log("Last Prize Winner Count:", lpwc);
    setLpwc(Number(lpwc));

    let p = [];
    for (let i = 0; i < contractGetInfo.prizeShareCount; i++) {
      const poolPrizes = await signerVars.contract.poolPrizes(
        poolData.poolId,
        i
      );
      p.push({
        wonPrize: i + 1,
        isWon: poolPrizes[0],
        share: Number(poolPrizes[1]),
      });
    }
    console.log(p);
    setPrizes(p);

    let dt = [];
    const isClaimed = await signerVars.contract.isClaimed(
      poolData.poolId,
      address
    );
    for (let i = 0; i < userTickets.length; i++) {
      if (userTickets[i] !== unSignerVars.AWAITING_ASSIGN) {
        const drawnTicketUsers = await signerVars.contract.drawnTicketUsers(
          poolData.poolId,
          userTickets[i]
        );
        dt.push({
          ticketNumber: userTickets[i],
          isClaimed,
          wonPrizeIndex: Number(drawnTicketUsers[0]),
          wonPrizeAmount: formatEther(drawnTicketUsers[1]),
          isWon: drawnTicketUsers[2],
          user: drawnTicketUsers[3],
          usedRefCode: decodeBytes32String(drawnTicketUsers[4]),
        });
      } else {
        dt.push({
          ticketNumber: userTickets[i],
        });
      }
    }
    console.log(dt);
    setTickets(dt);
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      isConnected &&
      signerVars.contract &&
      poolData &&
      poolData.poolId > 0 &&
      userTickets.length > 0
    ) {
      getTicketDetails();
    }
  }, [isConnected, poolData, signerVars, contractGetInfo, userTickets]);

  return (
    <div className={"loader"}>
      <div
        className={"prtitle"}
        style={{
          width: "80%",
          height: "75%",
          border: "1px solid #000",
          background: "#f6efe3",
          borderRadius: "12px",
        }}
      >
        <CloseButton
          style={{ top: "20px", right: "20px", position: "absolute" }}
          onClick={() => setUserTickets([])}
        />
        <h1>My Tickets in {poolData.name} Pool</h1>
        <div className="container">
          <div
            className="row row-cols-auto g-2"
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              overflowY: "scroll",
              height: "350px",
            }}
          >
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  className="spinner-border"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Loading</span>
              </div>
            ) : (
              tickets.length > 0 &&
              tickets.map((ticket, index) => (
                <div key={index} className="bg-light ticket">
                  {ticket.ticketNumber === unSignerVars.AWAITING_ASSIGN ? (
                    <>
                      <p>Ticket</p>
                      <p>
                        <span>
                          Waiting
                          <br />
                          Assign
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p>Ticket # {ticket.ticketNumber}</p>
                      <p>
                        <span>Won</span>
                        <span>{ticket.isWon ? "true" : "false"}</span>
                      </p>
                      <p>
                        <span>Claimed</span>
                        <span>{ticket.isClaimed ? "true" : "false"}</span>
                      </p>
                      {ticket.wonPrizeIndex === unSignerVars.AWAITING_DRAW ? (
                        isRevealedTickets ? (
                          <p style={{ backgroundColor: "red", color: "white" }}>
                            <span>Lose Ticket</span>
                          </p>
                        ) : (
                          <p>
                            <span>Waiting Draw</span>
                          </p>
                        )
                      ) : (
                        <>
                          <p>
                            <span>won Prize</span>
                            <span>{ticket.wonPrizeIndex}</span>
                          </p>
                          <p
                            style={{ backgroundColor: "green", color: "white" }}
                          >
                            <span>Prize Amount</span>
                            <span>
                              {Number(ticket.wonPrizeAmount).toFixed(2)} (
                              {poolData.prizeTokenSymbol})
                            </span>
                          </p>
                        </>
                      )}
                    </>
                  )}
                </div>
              ))
            )}
          </div>
          <hr />
          {!isLoading && (
            <div>
              <h3>{poolData.name} Pool Prizes</h3>
              <div className="container">
                <div style={{ fontSize: "12pt" }}>
                  <p style={{ margin: "0" }}>
                    Total Ticket: {poolData.maxTicketCount}
                  </p>
                  <p style={{ margin: "0" }}>
                    Total Prize:{" "}
                    {Number(formatEther(poolData.totalPrizeToken)).toFixed(2)} (
                    {poolData.prizeTokenSymbol})
                  </p>
                  <p style={{ margin: "0" }}>
                    Last Prize Winner Count: {lpwc} Ticket
                  </p>
                  <div
                    className="row row-cols-auto g-2"
                    style={{
                      justifyContent: "center",
                      padding: "20px",
                    }}
                  >
                    {prizes.length > 0 &&
                      prizes.map((prize, index) => (
                        <div
                          key={index}
                          className="bg-light"
                          style={{
                            margin: "0",
                            width: "150px",
                            border: "1px solid #000",
                            margin: "4px",
                          }}
                        >
                          <p>{prize.wonPrize}. winning ticket</p>
                          <p>% {Math.floor(prize.share / 100)}</p>
                          <p>
                            {Number(
                              (formatEther(poolData.totalPrizeToken) *
                                prize.share) /
                                10000
                            )}{" "}
                            {poolData.prizeTokenSymbol}
                          </p>
                        </div>
                      ))}
                  </div>
                  <p>
                    4. prize: will be divided equally among the{" "}
                    {lpwc > 0
                      ? lpwc
                      : poolData.maxTicketCount - (prizes.length - 1)}{" "}
                    winning tickets (
                    {prizes.length > 0 &&
                      Number(
                        (formatEther(poolData.totalPrizeToken) *
                          prizes[prizes.length - 1].share) /
                          (10000 * lpwc)
                      ).toFixed(2)}{" "}
                    {poolData.prizeTokenSymbol})
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserTickets;
