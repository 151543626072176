import React, { useState, useEffect, useRef } from "react";
import {
  Contract,
  decodeBytes32String,
  encodeBytes32String,
  formatEther,
  parseEther,
} from "ethers";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useWalletConnection } from "../../utility/useWalletConnection";
import { useData } from "../../utility/useData";
import Header from "../../components/header/Header";
import PageLoading from "../../components/popups/PageLoading";
import GoogleMaps from "../../components/google-maps/GoogleMaps";
import IERC20 from "../../abis/IERC20.json";
//css
import "./index.css";
//images
import Mobile from "../../assets/img/mobile2.jpg";
import { formatAddress } from "../../utility/Utils";

const Panel = ({ myLocation }) => {
  const { search, pathname, port } = useLocation();
  const [searchParams] = useSearchParams();
  const {
    isConnected,
    connectedWallet,
    address,
    signerVars,
    unSignerVars,
    errorMessage,
  } = useWalletConnection();

  const {
    decimals,
    initalResultMsg,
    showPoolDetail,
    poolData,
    togglePoolData,
    openData,
    closeData,
    updatePoolData,
    statusText,
  } = useData();

  const [pageLoading, setPageLoading] = useState(false);
  const [pageLoadingMsg, setPageLoadingMsg] = useState(null);
  const [btnLoading, setBtnLoading] = useState(null);
  const [btnLoadingMsg, setBtnLoadingMsg] = useState(null);
  const [resultMsg, setResultMsg] = useState(initalResultMsg);
  const [poolCount, setPoolCount] = useState(0);
  const [pools, setPools] = useState([]);
  const [refCodes, setRefCodes] = useState([]);
  const [selectedPool, setSelectedPool] = useState([]);
  const [clickMapSelected, setClickMapSelected] = useState(null);
  const [selectedLatLng, setSelectedLatLng] = useState({
    select: false,
    lat: 0,
    lng: 0,
  });
  const [vrfCallbacks, setVrfCallbacks] = useState({
    gasLimit: 0,
    maxTicket: 0,
  });

  const selectPoolId = useRef();
  const partnerPrizeTokenAmount = useRef();

  const adminAddress = useRef();
  const adminState = useRef();

  const codeOwnerRef = useRef();
  const codeRef = useRef();

  const newCallbackGasLimit = useRef();
  const newCallbackMaxTicket = useRef();

  const totalPrizeTokenRef = useRef();
  const lpwcRef = useRef();
  const percent1Ref = useRef();
  const percent2Ref = useRef();
  const percent3Ref = useRef();
  const percent4Ref = useRef();
  const percent5Ref = useRef();
  //createPool Refs
  const poolNameRef = useRef();
  const lockTokenAddressRef = useRef();
  const prizeTokenAddressRef = useRef();
  const joinDayRef = useRef();
  const lockDayRef = useRef();
  const totalPoolRef = useRef();
  const oneTicketOfTokenRef = useRef();
  //updateTicketLimit
  const newTicketLimit = useRef();
  //changeStatus Refs
  const csStatus = useRef();

  //TODO: ondalıklı total miktarlar denenecek

  const testPool1 = {
    lockTokenAddress: process.env.REACT_APP_LOCKTOKEN_ADDRESS,
    prizeTokenAddress: process.env.REACT_APP_PRIZETOKEN_ADDRESS,
    lockTokenSymbol: "LTT",
    prizeTokenSymbol: "PTT",
    poolStrDetails: ["Istanbul", "41.015137", "28.979530"],
    poolIntDetails: [
      Math.floor(new Date().getTime() / 1000) + 60,
      5,
      1,
      2000,
      100,
    ], //5 min join, 10 min lock
    prizeShares: [2300, 0, 3000, 2000, 1000],
  };

  const getPoolCount = async () => {
    const poolCounter = Number(await signerVars.contract.poolCounter());
    console.log(">>> Get poolCounter", poolCounter);
    setPoolCount(poolCounter);

    //get vrf vars
    const callbackGasLimit = Number(
      await signerVars.contract.callbackGasLimit()
    );
    const callbackMaxTicket = Number(
      await signerVars.contract.callbackMaxTicket()
    );
    setVrfCallbacks({
      gasLimit: callbackGasLimit,
      maxTicket: callbackMaxTicket,
    });
  };

  const setAdmin = async (buttonName) => {
    if (adminAddress.current.value) {
      setBtnLoadingMsg("Waiting...");
      setBtnLoading(buttonName);
      signerVars.contract
        .setAdmin(
          adminAddress.current.value,
          adminState.current.value === "0" ? false : true
        )
        .then(async (result) => {
          const receipt = await result.wait();
          for (let i = 0; i < receipt.logs.length; i++) {
            if (receipt.logs[i].eventName === "AdminSet") {
              setResultMsg({
                msgType: 2,
                urlTitle: "view TX",
                url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                msg: `Admin: ${formatAddress(
                  receipt.logs[i].args[0]
                )}, Current State:
                ${receipt.logs[i].args[1]}`,
              });
              setBtnLoadingMsg(null);
              setBtnLoading(null);
            }
          }
          console.log("receipt (AdminSet):", receipt);
        })
        .catch((error) => {
          setResultMsg({
            msgType: 1,
            msg: `(AdminSet) ${error.reason || error.message}`,
          });
          console.log("error (AdminSet):", error);
          setBtnLoadingMsg(null);
          setBtnLoading(null);
        });
    } else {
      setResultMsg({ msgType: 1, msg: "Please fill in the required fields" });
    }
  };

  const checkAdmin = async () => {
    if (adminAddress.current.value) {
      const result = await signerVars.contract.admins(
        adminAddress.current.value
      );
      setResultMsg({
        msgType: 2,
        msg: `${formatAddress(adminAddress.current.value)} is ${result}`,
      });
    } else {
      setResultMsg({ msgType: 1, msg: "Please fill in the required fields" });
    }
  };

  const updateVRF = async (buttonName) => {
    if (
      newCallbackGasLimit.current.value ||
      newCallbackMaxTicket.current.value
    ) {
      setBtnLoadingMsg("Waiting...");
      setBtnLoading(buttonName);
      signerVars.contract
        .updateVRF(
          Number(newCallbackGasLimit.current.value) > 0
            ? newCallbackGasLimit.current.value
            : 0,
          Number(newCallbackMaxTicket.current.value) > 0
            ? newCallbackMaxTicket.current.value
            : 0
        )
        .then(async (result) => {
          const receipt = await result.wait();
          for (let i = 0; i < receipt.logs.length; i++) {
            if (receipt.logs[i].eventName === "UpdatedVRF") {
              setResultMsg({
                msgType: 2,
                urlTitle: "view TX",
                url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                msg: `New GasLimit: ${receipt.logs[i].args[0]}, New Max Ticket:
                ${receipt.logs[i].args[1]}`,
              });
              setVrfCallbacks({
                gasLimit:
                  Number(receipt.logs[i].args[0]) > 0
                    ? Number(receipt.logs[i].args[0])
                    : vrfCallbacks.gasLimit,
                maxTicket:
                  Number(receipt.logs[i].args[1]) > 0
                    ? Number(receipt.logs[i].args[1])
                    : vrfCallbacks.maxTicket,
              });
              setBtnLoadingMsg(null);
              setBtnLoading(null);
            }
          }
          console.log("receipt (UpdatedVRF):", receipt);
        })
        .catch((error) => {
          setResultMsg({
            msgType: 1,
            msg: `(UpdatedVRF) ${error.reason || error.message}`,
          });
          console.log("error (UpdatedVRF):", error);
          setBtnLoadingMsg(null);
          setBtnLoading(null);
        });
    } else {
      setResultMsg({ msgType: 1, msg: "Please fill in the required fields" });
    }
  };

  const getRefCodes = async () => {
    let codes = [];
    for (let poolId = 1; poolId <= poolCount; poolId++) {
      const refCodesCount = Number(
        (await signerVars.contract.getInfo(poolId, address)).refCodesCount
      );
      for (let codeIndex = 0; codeIndex < refCodesCount; codeIndex++) {
        const refCode = await signerVars.contract.refCodes(poolId, codeIndex);
        codes.push({
          poolId,
          codeIndex,
          owner: refCode.owner,
          code: refCode.code,
        });
      }
    }
    console.log("refCodes:", refCodes);
    setRefCodes(codes.reverse());
  };

  const addRefCode = async (buttonName) => {
    if (
      Number(selectPoolId.current.value) > 0 &&
      codeOwnerRef.current.value !== "" &&
      codeRef.current.value !== ""
    ) {
      setBtnLoadingMsg("Waiting...");
      setBtnLoading(buttonName);
      signerVars.contract
        .addRefCode(
          Number(selectPoolId.current.value),
          codeOwnerRef.current.value,
          encodeBytes32String(codeRef.current.value)
        )
        .then(async (result) => {
          const receipt = await result.wait();
          for (let i = 0; i < receipt.logs.length; i++) {
            if (receipt.logs[i].eventName === "AddedRefCode") {
              setResultMsg({
                msgType: 2,
                urlTitle: "view TX",
                url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                msg: `Owner: ${receipt.logs[i].args[1]}, Code:
                ${decodeBytes32String(receipt.logs[i].args[2])}`,
              });
              getRefCodes();
              setBtnLoadingMsg(null);
              setBtnLoading(null);
            }
          }
          console.log("receipt (AddedRefCode):", receipt);
        })
        .catch((error) => {
          setResultMsg({
            msgType: 1,
            msg: `(AddedRefCode) ${error.reason || error.message}`,
          });
          console.log("error (AddedRefCode):", error);
          setBtnLoadingMsg(null);
          setBtnLoading(null);
        });
    } else {
      setResultMsg({ msgType: 1, msg: "Please fill in the required fields" });
    }
  };

  const removeRefCode = async (buttonName, poolId, codeIndex) => {
    setBtnLoadingMsg("");
    setBtnLoading(buttonName);
    signerVars.contract
      .removeRefCode(Number(poolId), Number(codeIndex))
      .then(async (result) => {
        const receipt = await result.wait();
        for (let i = 0; i < receipt.logs.length; i++) {
          if (receipt.logs[i].eventName === "RemovedRefCode") {
            setResultMsg({
              msgType: 2,
              urlTitle: "view TX",
              url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
              msg: `Owner: ${receipt.logs[i].args[1]}, Code:
              ${receipt.logs[i].args[2]}`,
            });
            getRefCodes();
            setBtnLoadingMsg(null);
            setBtnLoading(null);
          }
        }
        console.log("receipt (RemovedRefCode):", receipt);
      })
      .catch((error) => {
        setResultMsg({
          msgType: 1,
          msg: `(RemovedRefCode) ${error.reason || error.message}`,
        });
        console.log("error (RemovedRefCode):", error);
        setBtnLoadingMsg(null);
        setBtnLoading(null);
      });
  };

  const getToken = async (tokenAddress, userAddress) => {
    const contract = new Contract(tokenAddress, IERC20.abi, signerVars.signer);
    const symbol = await contract.symbol();
    const name = await contract.name();
    const decimals = await contract.decimals();
    const balance = await contract.balanceOf(userAddress);
    const allowanceAmount = await contract.allowance(
      address,
      process.env.REACT_APP_CONTRACT_ADDRESS
    );

    return { contract, symbol, name, decimals, balance, allowanceAmount };
  };

  const partnerTransfer = async (buttonName) => {
    if (
      Number(selectPoolId.current.value) > 0 &&
      Number(partnerPrizeTokenAmount.current.value) > 0
    ) {
      const totalPrizeToken = await signerVars.contract.totalPrizeToken(
        Number(selectPoolId.current.value)
      );
      if (
        Number(parseEther(partnerPrizeTokenAmount.current.value)) ===
        Number(totalPrizeToken)
      ) {
        const prizeToken = await getToken(
          await signerVars.contract.prizeToken(
            Number(selectPoolId.current.value)
          ),
          address
        ).catch((error) => {
          setResultMsg({
            msgType: 1,
            msg: `(prizeToken) ${error.reason || error.message}`,
          });
        });
        console.log(prizeToken);

        if (Number(prizeToken.allowanceAmount) < Number(totalPrizeToken)) {
          setBtnLoadingMsg("Waiting Approve...");
          setBtnLoading(buttonName);
          setPageLoadingMsg(`Please confirm the ${connectedWallet} request`);
          setPageLoading(true);
          prizeToken.contract
            .approve(process.env.REACT_APP_CONTRACT_ADDRESS, totalPrizeToken)
            .then(async (result) => {
              const receipt = await result.wait();
              setResultMsg({
                msgType: 2,
                urlTitle: "view TX",
                url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                msg: `Approval`,
              });
              setBtnLoadingMsg(null);
              setBtnLoading(null);
              console.log("receipt (Approve):", receipt);
              //partner transfer after approval
              setBtnLoadingMsg("Waiting Transfer...");
              setBtnLoading(buttonName);
              signerVars.contract
                .partnerTransfer(
                  Number(selectPoolId.current.value),
                  totalPrizeToken
                )
                .then(async (result) => {
                  const receipt = await result.wait();
                  for (let i = 0; i < receipt.logs.length; i++) {
                    if (receipt.logs[i].eventName === "PartnerTrasfer") {
                      setResultMsg({
                        msgType: 2,
                        urlTitle: "view TX",
                        url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                        msg: `Pool ID: ${Number(
                          receipt.logs[i].args[0]
                        )}, Sender:
                ${formatAddress(receipt.logs[i].args[1])}, Amount:
                ${formatEther(receipt.logs[i].args[2])}`,
                      });
                      setBtnLoadingMsg(null);
                      setBtnLoading(null);
                      setPageLoadingMsg(null);
                      setPageLoading(false);
                    }
                  }
                  console.log("receipt (PartnerTrasfer):", receipt);
                })
                .catch((error) => {
                  setResultMsg({
                    msgType: 1,
                    msg: `(PartnerTrasfer) ${error.reason || error.message}`,
                  });
                  console.log("error (PartnerTrasfer):", error);
                  setBtnLoadingMsg(null);
                  setBtnLoading(null);
                  setPageLoadingMsg(null);
                  setPageLoading(false);
                });
            })
            .catch((error) => {
              setResultMsg({
                msgType: 1,
                msg: `(Approve) ${error.reason || error.message}`,
              });
              console.log("error (Approve):", error);
              setBtnLoadingMsg(null);
              setBtnLoading(null);
              setPageLoadingMsg(null);
              setPageLoading(false);
            });
        } else {
          //direct partner transfer
          setBtnLoadingMsg("Waiting Transfer...");
          setBtnLoading(buttonName);
          setPageLoadingMsg(`Please confirm the ${connectedWallet} request`);
          setPageLoading(true);
          signerVars.contract
            .partnerTransfer(
              Number(selectPoolId.current.value),
              totalPrizeToken
            )
            .then(async (result) => {
              const receipt = await result.wait();
              for (let i = 0; i < receipt.logs.length; i++) {
                if (receipt.logs[i].eventName === "PartnerTrasfer") {
                  setResultMsg({
                    msgType: 2,
                    urlTitle: "view TX",
                    url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                    msg: `Pool ID: ${Number(receipt.logs[i].args[0])}, Sender:
                ${formatAddress(receipt.logs[i].args[1])}, Amount:
                ${formatEther(receipt.logs[i].args[2])}`,
                  });
                  setBtnLoadingMsg(null);
                  setBtnLoading(null);
                  setPageLoadingMsg(null);
                  setPageLoading(false);
                }
              }
              console.log("receipt (PartnerTrasfer):", receipt);
            })
            .catch((error) => {
              setResultMsg({
                msgType: 1,
                msg: `(PartnerTrasfer) ${error.reason || error.message}`,
              });
              console.log("error (PartnerTrasfer):", error);
              setBtnLoadingMsg(null);
              setBtnLoading(null);
              setPageLoadingMsg(null);
              setPageLoading(false);
            });
        }
      } else {
        setResultMsg({
          msgType: 1,
          msg: "The prize amount sent is not equal to the pool prize amount",
        });
      }
    } else {
      setResultMsg({ msgType: 1, msg: "Select the required fields" });
    }
  };

  const createPool = async (buttonName) => {
    if (isConnected) {
      //for test
      if (Number(process.env.REACT_APP_NETWORKID) === 31337) {
        lockTokenAddressRef.current.value =
          process.env.REACT_APP_LOCKTOKEN_ADDRESS;
        prizeTokenAddressRef.current.value =
          process.env.REACT_APP_PRIZETOKEN_ADDRESS;
      } else if (Number(process.env.REACT_APP_NETWORKID) === 11155111) {
        lockTokenAddressRef.current.value =
          "0x27cbDcB2b177F19736f74cB7385D8dC08c32e0b0";
        prizeTokenAddressRef.current.value =
          "0x57646512E7B301ef9C0f96b4887181B91aAB4EE4";
      } else if (Number(process.env.REACT_APP_NETWORKID) === 421614) {
        lockTokenAddressRef.current.value =
          "0x96743629F39F3E135486766375f83a94DF17aD4D";
        prizeTokenAddressRef.current.value =
          "0x0206CDDFD895FCE7E3d8464a06e5ccA268DFC1f4";
      } else if (Number(process.env.REACT_APP_NETWORKID) === 80002) {
        lockTokenAddressRef.current.value =
          "0x3992A4515963326294B43A2cd19dAbf4b9402E09";
        prizeTokenAddressRef.current.value =
          "0x0bbA8c209e43d00d32568E4180841d537cfcB726";
      }

      const poolStrDetails = [
        poolNameRef.current.value,
        selectedLatLng.lat.toString(),
        selectedLatLng.lng.toString(),
      ];

      const poolIntDetails = [
        Number(joinDayRef.current.value),
        Number(lockDayRef.current.value),
        parseEther(
          totalPoolRef.current.value !== "" ? totalPoolRef.current.value : "0"
        ),
        parseEther(
          oneTicketOfTokenRef.current.value !== ""
            ? oneTicketOfTokenRef.current.value
            : "0"
        ),
      ];

      console.log("poolStrDetails:", poolStrDetails);
      console.log("poolIntDetails:", poolIntDetails);

      if (
        selectedLatLng.select &&
        lockTokenAddressRef.current.value !== "" &&
        prizeTokenAddressRef.current.value !== "" &&
        poolStrDetails[0] !== "" &&
        poolStrDetails[1] !== "" &&
        poolStrDetails[2] !== "" &&
        poolIntDetails[0] > 0 &&
        poolIntDetails[1] > 0 &&
        poolIntDetails[2] > 0 &&
        poolIntDetails[3] > 0
      ) {
        const lockToken = await getToken(
          lockTokenAddressRef.current.value,
          address
        ).catch((error) => {
          setResultMsg({
            msgType: 1,
            msg: `(lockToken) ${error.reason || error.message}`,
          });
        });
        const prizeToken = await getToken(
          prizeTokenAddressRef.current.value,
          address
        ).catch((error) => {
          setResultMsg({
            msgType: 1,
            msg: `(prizeToken) ${error.reason || error.message}`,
          });
        });
        console.log("lockToken:", lockToken);
        console.log("prizeToken:", prizeToken);

        setBtnLoading(buttonName);
        setPageLoadingMsg(`Please confirm the ${connectedWallet} request`);
        setPageLoading(true);
        const estimateGas = Number(
          await signerVars.contract.createPool.estimateGas(
            lockTokenAddressRef.current.value,
            prizeTokenAddressRef.current.value,
            poolStrDetails,
            poolIntDetails
          )
        );
        console.log("estimateGas:", estimateGas);
        signerVars.contract
          .createPool(
            lockTokenAddressRef.current.value,
            prizeTokenAddressRef.current.value,
            poolStrDetails,
            poolIntDetails,
            { gasLimit: estimateGas }
          )
          .then(async (result) => {
            const receipt = await result.wait();
            for (let i = 0; i < receipt.logs.length; i++) {
              if (receipt.logs[i].eventName === "PoolCreated") {
                setResultMsg({
                  msgType: 2,
                  urlTitle: "view TX",
                  url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                  msg: `Created Pool ID: ${Number(receipt.logs[i].args[0])}`,
                });
                // await getPoolCount(); //droplisti günceller
                setPoolCount(Number(receipt.logs[i].args[0])); //droplisti günceller
                poolNameRef.current.value = "";
                lockTokenAddressRef.current.value = "";
                prizeTokenAddressRef.current.value = "";
                joinDayRef.current.value = "";
                lockDayRef.current.value = "";
                totalPoolRef.current.value = "";
                oneTicketOfTokenRef.current.value = "";
                setBtnLoadingMsg(null);
                setBtnLoading(null);
                setPageLoadingMsg(null);
                setPageLoading(false);
                setClickMapSelected(null);
                setSelectedLatLng({ select: false, lat: 0, lng: 0 });
              }
            }
            console.log("receipt (PoolCreated):", receipt);
          })
          .catch((error) => {
            setResultMsg({
              msgType: 1,
              msg: `(PoolCreated) ${error.reason || error.message}`,
            });
            console.log("error (PoolCreated):", error);
            setBtnLoadingMsg(null);
            setBtnLoading(null);
            setPageLoadingMsg(null);
            setPageLoading(false);
          });
      } else {
        setResultMsg({ msgType: 1, msg: "Please fill in the required fields" });
      }
    } else {
      setResultMsg({ msgType: 1, msg: "Please connect wallet" });
    }
  };

  const setPrizeShares = async (buttonName) => {
    const prizeShares = [
      parseEther(
        totalPrizeTokenRef.current.value !== ""
          ? totalPrizeTokenRef.current.value
          : "0"
      ),
      Number(lpwcRef.current.value),
      Number(percent1Ref.current.value),
      Number(percent2Ref.current.value),
      Number(percent3Ref.current.value),
    ];
    if (percent4Ref.current.value !== "") {
      prizeShares.push(Number(percent4Ref.current.value));
    }
    if (percent5Ref.current.value !== "") {
      prizeShares.push(Number(percent5Ref.current.value));
    }
    console.log("prizeShares:", prizeShares);

    if (
      selectedPool.length > 0 &&
      prizeShares[0] > 0 &&
      prizeShares[2] > 0 &&
      prizeShares[3] > 0 &&
      prizeShares[4] > 0
    ) {
      setBtnLoadingMsg("Waiting...");
      setBtnLoading(buttonName);
      setPageLoadingMsg(`Please confirm the ${connectedWallet} request`);
      setPageLoading(true);
      signerVars.contract
        .setPoolPrizes(Number(selectPoolId.current.value), prizeShares)
        .then(async (result) => {
          const receipt = await result.wait();
          for (let i = 0; i < receipt.logs.length; i++) {
            if (receipt.logs[i].eventName === "PoolPrizesCreated") {
              setResultMsg({
                msgType: 2,
                urlTitle: "view TX",
                url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                msg: `Set Prize & Shares Pool ID: ${Number(
                  receipt.logs[i].args[0]
                )}`,
              });
              totalPrizeTokenRef.current.value = "";
              lpwcRef.current.value = "";
              percent1Ref.current.value = "";
              percent2Ref.current.value = "";
              percent3Ref.current.value = "";
              percent4Ref.current.value = "";
              percent5Ref.current.value = "";
              setBtnLoadingMsg(null);
              setBtnLoading(null);
              setPageLoadingMsg(null);
              setPageLoading(false);
            }
          }
          console.log("receipt (PoolPrizesCreated):", receipt);
        })
        .catch((error) => {
          setResultMsg({
            msgType: 1,
            msg: `(PoolPrizesCreated) ${error.reason || error.message}`,
          });
          console.log("error (PoolPrizesCreated):", error);
          setBtnLoadingMsg(null);
          setBtnLoading(null);
          setPageLoadingMsg(null);
          setPageLoading(false);
        });
    } else {
      setResultMsg({ msgType: 1, msg: "Please fill in the required fields" });
    }
  };

  const getPoolPrizeShares = async (poolId) => {
    if (Number(poolId) > 0) {
      totalPrizeTokenRef.current.value = "";
      lpwcRef.current.value = "";
      percent1Ref.current.value = "";
      percent2Ref.current.value = "";
      percent3Ref.current.value = "";
      percent4Ref.current.value = "";
      percent5Ref.current.value = "";
      totalPrizeTokenRef.current.disabled = false;
      lpwcRef.current.disabled = false;
      percent1Ref.current.disabled = false;
      percent2Ref.current.disabled = false;
      percent3Ref.current.disabled = false;
      percent4Ref.current.disabled = false;
      percent5Ref.current.disabled = false;

      const getInfo = await signerVars.contract.getInfo(poolId, address);
      console.log("getInfo:", getInfo);
      if (Number(getInfo.prizeShareCount) > 0) {
        totalPrizeTokenRef.current.disabled = true;
        lpwcRef.current.disabled = true;
        percent1Ref.current.disabled = true;
        percent2Ref.current.disabled = true;
        percent3Ref.current.disabled = true;
        percent4Ref.current.disabled = true;
        percent5Ref.current.disabled = true;

        totalPrizeTokenRef.current.value =
          "Prize Token: " +
          formatEther(await signerVars.contract.totalPrizeToken(poolId));
        lpwcRef.current.value =
          "Last Prize Winner Count: " +
          (await signerVars.contract.lastPrizeWinnerCount(poolId));

        for (let i = 0; i < Number(getInfo.prizeShareCount); i++) {
          const poolPrizes = await signerVars.contract.poolPrizes(poolId, i);
          if (i === 0) {
            percent1Ref.current.value = "Percent 1: " + poolPrizes.share;
          }
          if (i === 1) {
            percent2Ref.current.value = "Percent 2: " + poolPrizes.share;
          }
          if (i === 2) {
            percent3Ref.current.value = "Percent 3: " + poolPrizes.share;
          }
          if (i === 3) {
            percent4Ref.current.value = "Percent 4: " + poolPrizes.share;
          }
          if (i === 4) {
            percent5Ref.current.value = "Percent 5: " + poolPrizes.share;
          }
        }
      }
    } else {
      totalPrizeTokenRef.current.value = "";
      lpwcRef.current.value = "";
      percent1Ref.current.value = "";
      percent2Ref.current.value = "";
      percent3Ref.current.value = "";
      percent4Ref.current.value = "";
      percent5Ref.current.value = "";
      totalPrizeTokenRef.current.disabled = false;
      lpwcRef.current.disabled = false;
      percent1Ref.current.disabled = false;
      percent2Ref.current.disabled = false;
      percent3Ref.current.disabled = false;
      percent4Ref.current.disabled = false;
      percent5Ref.current.disabled = false;
    }
  };

  const selectPool = async (_poolId) => {
    if (Number(_poolId) > 0) {
      const selectPool = pools.find(
        ({ poolId }) => Number(poolId) === Number(_poolId)
      );
      setSelectedPool([selectPool]);
      getPoolPrizeShares(selectPool.poolId);
    } else {
      setSelectedPool([]);
      getPoolPrizeShares(0);
    }
  };

  const updateTicketLimit = async (buttonName) => {
    if (
      Number(selectPoolId.current.value) > 0 &&
      Number(newTicketLimit.current.value) > 0
    ) {
      setBtnLoadingMsg("Waiting...");
      setBtnLoading(buttonName);
      signerVars.contract
        .updateTicketLimit(
          Number(selectPoolId.current.value),
          Number(newTicketLimit.current.value)
        )
        .then(async (result) => {
          const receipt = await result.wait();
          for (let i = 0; i < receipt.logs.length; i++) {
            if (receipt.logs[i].eventName === "UpdatedTicketLimit") {
              setResultMsg({
                msgType: 2,
                urlTitle: "view TX",
                url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                msg: `Pool ID: ${Number(receipt.logs[i].args[0])}, Old Limit:
                ${Number(receipt.logs[i].args[1])}, New Limit:
                ${Number(receipt.logs[i].args[2])}`,
              });
              setBtnLoadingMsg(null);
              setBtnLoading(null);
            }
          }
          console.log("receipt (UpdatedTicketLimit):", receipt);
        })
        .catch((error) => {
          setResultMsg({
            msgType: 1,
            msg: `(UpdatedTicketLimit) ${error.reason || error.message}`,
          });
          console.log("error (UpdatedTicketLimit):", error);
          setBtnLoadingMsg(null);
          setBtnLoading(null);
        });
    } else {
      setResultMsg({ msgType: 1, msg: "Select the required fields" });
    }
  };

  const changeStatus = async (buttonName) => {
    if (
      Number(selectPoolId.current.value) > 0 &&
      Number(csStatus.current.value) > 0
    ) {
      const poolTicketLimit = Number(
        await signerVars.contract.poolTicketLimit(
          Number(selectPoolId.current.value)
        )
      );
      if (Number(csStatus.current.value) !== 2 || poolTicketLimit > 0) {
        setBtnLoadingMsg("Waiting...");
        setBtnLoading(buttonName);
        signerVars.contract
          .changeStatus(
            Number(selectPoolId.current.value),
            Number(csStatus.current.value)
          )
          .then(async (result) => {
            const receipt = await result.wait();
            for (let i = 0; i < receipt.logs.length; i++) {
              if (receipt.logs[i].eventName === "ChangedStatus") {
                setResultMsg({
                  msgType: 2,
                  urlTitle: "view TX",
                  url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                  msg: `Pool ID: ${Number(
                    receipt.logs[i].args[0]
                  )}, Current Status:
                  ${Number(receipt.logs[i].args[1])}`,
                });
                // const arrayPoolIndex = pools.findIndex(
                //   (pool) =>
                //     Number(pool.poolId) === Number(selectPoolId.current.value)
                // );
                pools[Number(selectPoolId.current.value)].status = Number(
                  receipt.logs[i].args[1]
                );
                pools[Number(selectPoolId.current.value)].statusLabel =
                  statusText(Number(receipt.logs[i].args[1]));
                setBtnLoadingMsg(null);
                setBtnLoading(null);
              }
            }
            console.log("receipt (ChangedStatus):", receipt);
          })
          .catch((error) => {
            setResultMsg({
              msgType: 1,
              msg: `(ChangedStatus) ${error.reason || error.message}`,
            });
            console.log("error (ChangedStatus):", error);
            setBtnLoadingMsg(null);
            setBtnLoading(null);
          });
      } else {
        setResultMsg({
          msgType: 1,
          msg: "The pool ticket limit cannot be zero",
        });
      }
    } else {
      setResultMsg({ msgType: 1, msg: "Select the required fields" });
    }
  };

  const revealTickets = async (buttonName) => {
    if (Number(selectPoolId.current.value) > 0) {
      const nowTime = Math.floor(new Date().getTime() / 1000);
      const pool = await signerVars.contract.pools(
        Number(selectPoolId.current.value)
      );
      const availableLockTime =
        Number(pool.createDate) + Number(pool.joinDay) + Number(pool.lockDay);
      if (nowTime > availableLockTime) {
        const getInfo = await signerVars.contract.getInfo(
          Number(selectPoolId.current.value),
          address
        );
        const assignTicketCount = Number(getInfo.assignTicketCount);
        if (assignTicketCount > 0) {
          if (
            Number(
              await signerVars.contract.lastPrizeWinnerCount(
                Number(selectPoolId.current.value)
              )
            ) > 0
          ) {
            const isDrawn = (
              await signerVars.contract.poolPrizes(
                Number(selectPoolId.current.value),
                0
              )
            ).isDrawn;
            if (!isDrawn) {
              const prizeSeedsCount = Number(getInfo.prizeSeedsCount);
              if (prizeSeedsCount < 1) {
                setBtnLoadingMsg("Waiting...");
                setBtnLoading(buttonName);
                setPageLoadingMsg(
                  `Please confirm the ${connectedWallet} request`
                );
                setPageLoading(true);
                const estimateGas = Number(
                  await signerVars.contract.revealTicket.estimateGas(
                    Number(selectPoolId.current.value)
                  )
                );
                console.log("estimateGas:", estimateGas);
                signerVars.contract
                  .revealTicket(Number(selectPoolId.current.value), {
                    gasLimit: estimateGas,
                  })
                  .then(async (result) => {
                    const receipt = await result.wait();
                    for (let i = 0; i < receipt.logs.length; i++) {
                      if (receipt.logs[i].eventName === "TicketRevealed") {
                        setResultMsg({
                          msgType: 2,
                          urlTitle: "view TX",
                          url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                          msg: `Pool ID: ${Number(
                            receipt.logs[i].args[0]
                          )}, Random words count:
                    ${Number(receipt.logs[i].args[1])}`,
                        });
                        setBtnLoadingMsg(null);
                        setBtnLoading(null);
                        setPageLoadingMsg(null);
                        setPageLoading(false);
                      }
                    }
                    console.log("receipt (TicketRevealed):", receipt);
                  })
                  .catch((error) => {
                    setResultMsg({
                      msgType: 1,
                      msg: `(TicketRevealed) ${error.reason || error.message}`,
                    });
                    console.log("error (TicketRevealed):", error);
                    setBtnLoadingMsg(null);
                    setBtnLoading(null);
                    setPageLoadingMsg(null);
                    setPageLoading(false);
                  });
              } else {
                setResultMsg({ msgType: 1, msg: "Prize already reveal" });
              }
            } else {
              setResultMsg({ msgType: 1, msg: "Prize already drawn" });
            }
          } else {
            setResultMsg({ msgType: 1, msg: "Prize not determined" });
          }
        } else {
          setResultMsg({ msgType: 1, msg: "Nobody has joined the pool yet" });
        }
      } else {
        setResultMsg({ msgType: 1, msg: "Lock time has not ended" });
      }
    } else {
      setResultMsg({ msgType: 1, msg: "Select the required fields" });
    }
  };

  const prizesShared = async (buttonName) => {
    if (Number(selectPoolId.current.value) > 0) {
      const prizeSeeds = Number(
        (
          await signerVars.contract.getInfo(
            Number(selectPoolId.current.value),
            address
          )
        ).prizeSeedsCount
      );
      if (prizeSeeds > 0) {
        const status = (
          await signerVars.contract.pools(selectPoolId.current.value)
        ).status;
        console.log(status);
        if (Number(status) < 4) {
          setBtnLoadingMsg("Waiting...");
          setBtnLoading(buttonName);
          setPageLoadingMsg(`Please confirm the ${connectedWallet} request`);
          setPageLoading(true);
          signerVars.contract
            .prizesShared(Number(selectPoolId.current.value))
            .then(async (result) => {
              const receipt = await result.wait();
              for (let i = 0; i < receipt.logs.length; i++) {
                if (receipt.logs[i].eventName === "SharedPrizes") {
                  setResultMsg({
                    msgType: 2,
                    urlTitle: "view TX",
                    url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                    msg: `Pool ID: ${Number(receipt.logs[i].args[0])}`,
                  });
                  setBtnLoadingMsg(null);
                  setBtnLoading(null);
                  setPageLoadingMsg(null);
                  setPageLoading(false);
                }
              }
              console.log("receipt (SharedPrizes):", receipt);
            })
            .catch((error) => {
              setResultMsg({
                msgType: 1,
                msg: `(SharedPrizes) ${error.reason || error.message}`,
              });
              console.log("error (SharedPrizes):", error);
              setBtnLoadingMsg(null);
              setBtnLoading(null);
              setPageLoadingMsg(null);
              setPageLoading(false);
            });
        } else {
          setResultMsg({ msgType: 1, msg: "Prize already shared" });
        }
      } else {
        setResultMsg({ msgType: 1, msg: "Not reveal tickets" });
      }
    } else {
      setResultMsg({ msgType: 1, msg: "Select the required fields" });
    }
  };

  useEffect(() => {
    setResultMsg(initalResultMsg);
  }, [address]);

  useEffect(() => {
    if (signerVars.contract && myLocation.lat && myLocation.lng) {
      getPoolCount();
      const myLoc = {
        poolId: 0,
        poolName: "My Location",
        location: { lat: myLocation.lat, lng: myLocation.lng },
        status: 0,
        statusLabel: null,
      };

      if (poolCount > 0) {
        async function getPools() {
          let pools = [];
          pools.push(myLoc);
          for (let poolId = 1; poolId <= poolCount; poolId++) {
            const pool = await signerVars.contract.pools(poolId);
            const poolObj = {
              poolId,
              poolName: pool.name,
              location: {
                lat: Number(pool.lat),
                lng: Number(pool.long),
              },
              status: Number(pool.status),
              statusLabel: statusText(Number(pool.status)),
            };
            pools.push(poolObj);
          }
          setPools(pools);
        }

        getPools();
        getRefCodes();
      } else {
        setPools([myLoc]);
      }
    }
  }, [signerVars, poolCount, myLocation.lat, myLocation.lng]);

  useEffect(() => {
    if (selectedPool.length > 0) {
      selectPoolId.current.value = selectedPool[0].poolId;
    } else {
      if (selectPoolId.current) {
        selectPoolId.current.value = 0;
      }
    }
  }, [selectedPool]);

  return (
    <>
      {pageLoading && <PageLoading pageLoadingMsg={pageLoadingMsg} />}
      <div className="pop">
        <img src={Mobile} />
      </div>
      <Header />
      <div className="container ">
        <h3 className="title">
          {signerVars && signerVars.isDeployer && "Deployer Panel"}
          {signerVars && signerVars.isAdmin && "Admin Panel"}
          {signerVars && !signerVars.isDeployer && !signerVars.isAdmin && (
            <b style={{ color: "red" }}> (No Authorized)</b>
          )}
        </h3>
        <div className="row">
          <div className="col-3" />
          <div className="col-9">
            {resultMsg.msgType > 0 && (
              <p
                className={
                  resultMsg.msgType === 1 ? "error-msg" : "success-msg"
                }
              >
                {resultMsg.msgType === 1
                  ? `Error: ${resultMsg.msg} `
                  : `Success: ${resultMsg.msg} `}
                {resultMsg.url && (
                  <Link
                    to={resultMsg.url}
                    target="_blank"
                    className="padding-0-1"
                  >
                    {resultMsg.urlTitle}
                  </Link>
                )}
              </p>
            )}
          </div>
        </div>
        {signerVars && (signerVars.isDeployer || signerVars.isAdmin) && (
          <div className="row">
            <div
              className="col-3"
              style={{
                border: "1px solid #352",
                borderRadius: "14px",
                margin: "4px 0",
                paddingBottom: "10px",
              }}
            >
              <h5 style={{ textAlign: "center", marginTop: "3%" }}>
                {signerVars.isDeployer ? (
                  <>Welcome Deployer</>
                ) : signerVars.isAdmin ? (
                  <>Welcome Admin</>
                ) : null}
              </h5>
              <div style={{ paddingBottom: "4%" }}>
                <select
                  defaultValue={0}
                  className="form-control"
                  ref={selectPoolId}
                  onChange={() => selectPool(selectPoolId.current.value)}
                >
                  <option value="0">Please Select Pool</option>
                  {pools.map(
                    (pool) =>
                      pool.poolId !== 0 && (
                        <option value={pool.poolId} key={pool.poolId}>
                          {pool.poolName} ({pool.poolId})
                        </option>
                      )
                  )}
                </select>
              </div>

              <hr />

              <div className="list-group">
                <Link
                  to={"?f=create"}
                  className={
                    searchParams.get("f") !== "create" &&
                    searchParams.get("f") !== null
                      ? "list-group-item list-group-item-action"
                      : "list-group-item list-group-item-action active"
                  }
                >
                  Pool Create & Settings
                </Link>
                {signerVars.isDeployer && (
                  <Link
                    to={"?f=admins"}
                    className={
                      searchParams.get("f") !== "admins"
                        ? "list-group-item list-group-item-action"
                        : "list-group-item list-group-item-action active"
                    }
                  >
                    Admins
                  </Link>
                )}
                {signerVars.isDeployer && (
                  <Link
                    to={"?f=ref-codes"}
                    className={
                      searchParams.get("f") !== "ref-codes"
                        ? "list-group-item list-group-item-action"
                        : "list-group-item list-group-item-action active"
                    }
                  >
                    Ref Codes
                  </Link>
                )}
                {signerVars.isDeployer && (
                  <Link
                    to={"?f=ticket-limit"}
                    className={
                      searchParams.get("f") !== "ticket-limit"
                        ? "list-group-item list-group-item-action"
                        : "list-group-item list-group-item-action active"
                    }
                  >
                    Ticket Limit
                  </Link>
                )}
                <Link
                  to={"?f=partner-transfer"}
                  className={
                    searchParams.get("f") !== "partner-transfer"
                      ? "list-group-item list-group-item-action"
                      : "list-group-item list-group-item-action active"
                  }
                >
                  Partner Transfer
                </Link>
                {signerVars.isDeployer && (
                  <Link
                    to={"?f=change-status"}
                    className={
                      searchParams.get("f") !== "change-status"
                        ? "list-group-item list-group-item-action"
                        : "list-group-item list-group-item-action active"
                    }
                  >
                    Change Status
                  </Link>
                )}
                <Link
                  to={"?f=reveal-shared"}
                  className={
                    searchParams.get("f") !== "reveal-shared"
                      ? "list-group-item list-group-item-action"
                      : "list-group-item list-group-item-action active"
                  }
                >
                  Reveal & Shared
                </Link>
                {signerVars.isDeployer && (
                  <Link
                    to={"?f=update-vrf"}
                    className={
                      searchParams.get("f") !== "update-vrf"
                        ? "list-group-item list-group-item-action"
                        : "list-group-item list-group-item-action active"
                    }
                  >
                    Update VRF
                  </Link>
                )}
              </div>
            </div>
            <div
              className="col-9"
              style={{ overflowY: "scroll", maxHeight: "70vh" }}
            >
              {/* Set Admin */}
              {signerVars.isDeployer && (
                <div
                  className="section-functions"
                  style={
                    searchParams.get("f") === "admins"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h5>Set Admin</h5>
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Admin Address"
                        ref={adminAddress}
                      />
                    </div>
                    <div className="col">
                      <select
                        defaultValue={0}
                        className="form-control"
                        ref={adminState}
                      >
                        <option value={0}>false</option>
                        <option value={1}>true</option>
                      </select>
                    </div>
                    <div className="col">
                      <button
                        className={
                          btnLoading && btnLoading === "Change"
                            ? "btn btn-dark btn-disabled"
                            : "btn btn-dark"
                        }
                        onClick={() => setAdmin("Set Admin")}
                      >
                        {btnLoading && btnLoading === "Set Admin" ? (
                          <>
                            <span className="spinner-border spinner-border-sm"></span>
                            {` ${btnLoadingMsg}`}
                          </>
                        ) : (
                          "Set Admin"
                        )}
                      </button>
                      <button
                        className="btn btn-dark"
                        style={{ marginLeft: "6px" }}
                        onClick={() => checkAdmin()}
                      >
                        Check Admin
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Create Pool */}
              <div
                className="section-functions"
                style={
                  searchParams.get("f") === "create" ||
                  searchParams.get("f") === null
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h5>Create Pools</h5>
                <div className="row">
                  <div className="col">
                    <div style={{ marginBottom: "6px" }}>
                      <span>
                        Lat/Lng:
                        {selectedLatLng.select ? (
                          ` ${selectedLatLng.lat
                            .toString()
                            .substring(0, 9)} / ${selectedLatLng.lng
                            .toString()
                            .substring(0, 9)}`
                        ) : (
                          <span style={{ color: "red" }}> Not selected</span>
                        )}
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pool Name"
                      ref={poolNameRef}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lock Token Address"
                      ref={lockTokenAddressRef}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prize Token Address"
                      ref={prizeTokenAddressRef}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Join Day Count"
                      ref={joinDayRef}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lock Day Count"
                      ref={lockDayRef}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Total Pool"
                      ref={totalPoolRef}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="One Ticket of Token"
                      ref={oneTicketOfTokenRef}
                    />
                  </div>
                  <div className="col">
                    <GoogleMaps
                      myLat={myLocation.lat}
                      myLng={myLocation.lng}
                      pools={pools}
                      selectedPool={selectedPool}
                      setSelectedPool={setSelectedPool}
                      getPoolPrizeShares={getPoolPrizeShares}
                      setSelectedLatLng={setSelectedLatLng}
                      clickMapSelected={clickMapSelected}
                      setClickMapSelected={setClickMapSelected}
                    />
                  </div>
                </div>
                <div className="d-grid gap-2 col-4 mx-auto padding-2">
                  <button
                    className={
                      btnLoading && btnLoading === "Create"
                        ? "btn btn-dark btn-disabled"
                        : "btn btn-dark"
                    }
                    onClick={() => createPool("Create")}
                  >
                    {btnLoading && btnLoading === "Create" ? (
                      <>
                        <span className="spinner-border spinner-border-sm"></span>
                        {btnLoadingMsg && ` ${btnLoadingMsg}`}
                      </>
                    ) : (
                      "Create"
                    )}
                  </button>
                </div>
              </div>

              {/* Set Pool Prize & Shares */}
              <div
                className="section-functions"
                style={
                  searchParams.get("f") === "create" ||
                  searchParams.get("f") === null
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h5>Setting Pool Prize & Shares</h5>
                <label className="form-label">
                  Selected Pool:{" "}
                  {selectedPool.length > 0 ? (
                    `${selectedPool[0].poolName} (${selectedPool[0].statusLabel})`
                  ) : (
                    <span style={{ color: "red" }}>Not selected</span>
                  )}
                </label>
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Total Prize Token Amount"
                      ref={totalPrizeTokenRef}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Prize Winner Count"
                      ref={lpwcRef}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="1. Of Percentage"
                      ref={percent1Ref}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="2. Of Percentage"
                      ref={percent2Ref}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="3. Of Percentage"
                      ref={percent3Ref}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="4. Of Percentage"
                      ref={percent4Ref}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="5. Of Percentage"
                      ref={percent5Ref}
                    />
                  </div>
                </div>
                <div className="d-grid gap-2 col-4 mx-auto padding-2">
                  <button
                    className={
                      btnLoading && btnLoading === "Save"
                        ? "btn btn-dark btn-disabled"
                        : "btn btn-dark"
                    }
                    onClick={() => setPrizeShares("Save", testPool1)}
                  >
                    {btnLoading && btnLoading === "Save" ? (
                      <>
                        <span className="spinner-border spinner-border-sm"></span>
                        {` ${btnLoadingMsg}`}
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>

              {/* Add/Remove Ref Code */}
              {signerVars.isDeployer && (
                <div
                  className="section-functions"
                  style={
                    searchParams.get("f") === "ref-codes"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h5>Add/Remove Ref Code</h5>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">
                        Selected Pool:{" "}
                        {selectedPool.length > 0 ? (
                          `${selectedPool[0].poolName} (${selectedPool[0].statusLabel})`
                        ) : (
                          <span style={{ color: "red" }}>Not selected</span>
                        )}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Owner Address"
                        ref={codeOwnerRef}
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ref Code"
                        ref={codeRef}
                      />
                      <button
                        className={
                          btnLoading && btnLoading === "Add"
                            ? "btn btn-dark btn-disabled"
                            : "btn btn-dark"
                        }
                        style={{ marginLeft: "10px" }}
                        onClick={() => addRefCode("Add")}
                      >
                        {btnLoading && btnLoading === "Add" ? (
                          <>
                            <span className="spinner-border spinner-border-sm"></span>
                            {` ${btnLoadingMsg}`}
                          </>
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                    <div
                      className="col"
                      style={{
                        border: "1px solid #ddd",
                        padding: "4px",
                        marginRight: "10px",
                        borderRadius: "4px",
                      }}
                    >
                      <ul
                        className="list-group"
                        style={{ height: "150px", overflowY: "scroll" }}
                      >
                        {refCodes.length > 0 &&
                          refCodes.map((item, index) => (
                            <li
                              className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                              key={index}
                            >
                              {`${item.poolId} > ${decodeBytes32String(
                                item.code
                              )} (${formatAddress(item.owner)})`}
                              <span
                                className={
                                  btnLoading &&
                                  btnLoading ===
                                    `RemoveRef-${item.poolId}-${item.codeIndex}`
                                    ? "badge bg-danger rounded-pill btn-disabled"
                                    : "badge bg-danger rounded-pill"
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  removeRefCode(
                                    `RemoveRef-${item.poolId}-${item.codeIndex}`,
                                    item.poolId,
                                    item.codeIndex
                                  )
                                }
                              >
                                {btnLoading &&
                                btnLoading ===
                                  `RemoveRef-${item.poolId}-${item.codeIndex}` ? (
                                  <>
                                    <span className="spinner-border spinner-border-sm"></span>
                                    {` ${btnLoadingMsg}`}
                                  </>
                                ) : (
                                  "X"
                                )}
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {/* Partner Transfer */}
              <div
                className="section-functions"
                style={
                  searchParams.get("f") === "partner-transfer"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h5>Partner Transfer</h5>
                <div className="row">
                  <div className="col">
                    <label className="form-label">
                      Selected Pool:{" "}
                      {selectedPool.length > 0 ? (
                        `${selectedPool[0].poolName} (${selectedPool[0].statusLabel})`
                      ) : (
                        <span style={{ color: "red" }}>Not selected</span>
                      )}
                    </label>
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prize Token Amount"
                      ref={partnerPrizeTokenAmount}
                    />
                  </div>
                  <div className="col">
                    <button
                      className={
                        btnLoading && btnLoading === "Send"
                          ? "btn btn-dark btn-disabled"
                          : "btn btn-dark"
                      }
                      onClick={() => partnerTransfer("Send")}
                    >
                      {btnLoading && btnLoading === "Send" ? (
                        <>
                          <span className="spinner-border spinner-border-sm"></span>
                          {` ${btnLoadingMsg}`}
                        </>
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* Update Ticket Limit */}
              {signerVars.isDeployer && (
                <div
                  className="section-functions"
                  style={
                    searchParams.get("f") === "ticket-limit"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h5>Update Ticket Limit</h5>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">
                        Selected Pool:{" "}
                        {selectedPool.length > 0 ? (
                          `${selectedPool[0].poolName} (${selectedPool[0].statusLabel})`
                        ) : (
                          <span style={{ color: "red" }}>Not selected</span>
                        )}
                      </label>
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="New Ticket Limit"
                        ref={newTicketLimit}
                      />
                    </div>
                    <div className="col">
                      <button
                        className={
                          btnLoading && btnLoading === "Update"
                            ? "btn btn-dark btn-disabled"
                            : "btn btn-dark"
                        }
                        onClick={() => updateTicketLimit("Update")}
                      >
                        {btnLoading && btnLoading === "Update" ? (
                          <>
                            <span className="spinner-border spinner-border-sm"></span>
                            {` ${btnLoadingMsg}`}
                          </>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Change Status */}
              {signerVars.isDeployer && (
                <div
                  className="section-functions"
                  style={
                    searchParams.get("f") === "change-status"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h5>Change Status</h5>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">
                        Selected Pool:{" "}
                        {selectedPool.length > 0 ? (
                          `${selectedPool[0].poolName} (${selectedPool[0].statusLabel})`
                        ) : (
                          <span style={{ color: "red" }}>Not selected</span>
                        )}
                      </label>
                    </div>
                    <div className="col">
                      <select
                        defaultValue={0}
                        className="form-control"
                        ref={csStatus}
                      >
                        <option value="0">Select Status (INVALID)</option>
                        <option value="1">1 (DEACTIVE)</option>
                        <option value="2">2 (JOIN)</option>
                        <option value="3">3 (LOCK)</option>
                        <option value="4">4 (CLAIM)</option>
                        <option value="5">5 (ENDED)</option>
                      </select>
                    </div>
                    <div className="col">
                      <button
                        className={
                          btnLoading && btnLoading === "Change"
                            ? "btn btn-dark btn-disabled"
                            : "btn btn-dark"
                        }
                        onClick={() => changeStatus("Change")}
                      >
                        {btnLoading && btnLoading === "Change" ? (
                          <>
                            <span className="spinner-border spinner-border-sm"></span>
                            {` ${btnLoadingMsg}`}
                          </>
                        ) : (
                          "Change"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Reveal Tickets & Prizes Shared */}
              <div
                className="section-functions"
                style={
                  searchParams.get("f") === "reveal-shared"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="row">
                  {/* Reveal Tickets */}
                  <div className="col">
                    <h5>Reveal Tickets</h5>
                    <label className="form-label">
                      Selected Pool:{" "}
                      {selectedPool.length > 0 ? (
                        `${selectedPool[0].poolName} (${selectedPool[0].statusLabel})`
                      ) : (
                        <span style={{ color: "red" }}>Not selected</span>
                      )}
                    </label>
                    <button
                      className={
                        btnLoading && btnLoading === "Reveal"
                          ? "btn btn-dark btn-disabled"
                          : "btn btn-dark"
                      }
                      style={{ marginLeft: "10px" }}
                      onClick={() => revealTickets("Reveal")}
                    >
                      {btnLoading && btnLoading === "Reveal" ? (
                        <>
                          <span className="spinner-border spinner-border-sm"></span>
                          {` ${btnLoadingMsg}`}
                        </>
                      ) : (
                        "Reveal"
                      )}
                    </button>
                  </div>
                  {/* Prizes Shared */}
                  <div className="col">
                    <h5>Prizes Shared</h5>
                    <label className="form-label">
                      Selected Pool:{" "}
                      {selectedPool.length > 0 ? (
                        `${selectedPool[0].poolName} (${selectedPool[0].statusLabel})`
                      ) : (
                        <span style={{ color: "red" }}>Not selected</span>
                      )}
                    </label>
                    <button
                      className={
                        btnLoading && btnLoading === "Share"
                          ? "btn btn-dark btn-disabled"
                          : "btn btn-dark"
                      }
                      style={{ marginLeft: "10px" }}
                      onClick={() => prizesShared("Share")}
                    >
                      {btnLoading && btnLoading === "Share" ? (
                        <>
                          <span className="spinner-border spinner-border-sm"></span>
                          {` ${btnLoadingMsg}`}
                        </>
                      ) : (
                        "Share"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* Update VRF */}
              {signerVars.isDeployer && (
                <div
                  className="section-functions"
                  style={
                    searchParams.get("f") === "update-vrf"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h5>Update VRF</h5>
                  <div className="row">
                    <p style={{ textAlign: "start" }}>
                      Callback Gas Limit: {vrfCallbacks.gasLimit}
                    </p>
                    <p style={{ textAlign: "start" }}>
                      Callback Max Ticket: {vrfCallbacks.maxTicket}
                    </p>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="New Callback GasLimit"
                        ref={newCallbackGasLimit}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="New Callback Max Ticket"
                        ref={newCallbackMaxTicket}
                      />
                    </div>
                    <div className="col">
                      <button
                        className={
                          btnLoading && btnLoading === "Update VRF"
                            ? "btn btn-dark btn-disabled"
                            : "btn btn-dark"
                        }
                        onClick={() => updateVRF("Update VRF")}
                      >
                        {btnLoading && btnLoading === "Update VRF" ? (
                          <>
                            <span className="spinner-border spinner-border-sm"></span>
                            {` ${btnLoadingMsg}`}
                          </>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "small",
                paddingTop: "3px",
              }}
            >
              Vestate 2024 © Meletto® Company
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Panel;
