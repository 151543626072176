import React, { useRef, useEffect, useState } from "react";
import * as Cesium from "cesium";
import RedPin from "../../assets/img/pin-red.png";

const CesiumMap = React.forwardRef((props, ref) => {
  const [loadingMap, setLoadingMap] = useState(true);
  const cesiumContainer = useRef(null);
  const viewer = useRef(null);
  Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxNTlhNWYxNS0xYzUxLTRiMGUtYTJmNS1kYjFhOTlhY2MxMzAiLCJpZCI6MTg5ODMwLCJpYXQiOjE3MDUzMjQ1NjF9.zBgOmBYbCpgW3c67IFWrVQAZAl30oakUCBjUzOtB1o8";
  
  useEffect(() => {
    const bingMapsAerialWithLabels = Cesium.ImageryLayer.fromProviderAsync(
      Cesium.IonImageryProvider.fromAssetId(3)
    );

    if (props.poolCount === null) {
      //loading
      setLoadingMap(true);
    } else if (props.myLng && props.myLat && props.poolCount < 1) {
      {viewer.current = new Cesium.Viewer(cesiumContainer.current, {
        shouldAnimate: true,
        baseLayer: bingMapsAerialWithLabels,
        baseLayerPicker: false,
        infoBox: false,
      });
      const initialPosition = Cesium.Cartesian3.fromDegrees(props.myLng, props.myLat, props.defDistance);
      viewer.current.camera.setView({ destination: initialPosition });}
      setLoadingMap(false);
    } else if (props.myLng && props.myLat && props.poolCount > 0 && props.pools.length > 0) {
      {viewer.current = new Cesium.Viewer(cesiumContainer.current, {
        shouldAnimate: true,
        baseLayer: bingMapsAerialWithLabels,
        baseLayerPicker: false,
        infoBox: false,
      });
      const initialPosition = Cesium.Cartesian3.fromDegrees(props.myLng, props.myLat, props.defDistance);
      viewer.current.camera.setView({ destination: initialPosition });}

      const scene = viewer.current.scene;
      const handler = new Cesium.ScreenSpaceEventHandler(scene.canvas);

      handler.setInputAction((event) => {
        const marker = viewer.current.scene.pick(event.position);
        if (Cesium.defined(marker)) {
          const pool = marker.id.pool;
          console.log(pool);
          props.handleFlyToCity({
            poolId: pool.poolId,
            name: pool.name,
            img: pool.img,
            createDate: pool.createDate,
            joinDay: pool.joinDay,
            lockDay: pool.lockDay,
            totalPool: pool.totalPool,
            oneTicketOfToken: pool.oneTicketOfToken,
            maxTicketCount: pool.maxTicketCount,
            minTicketCount: pool.minTicketCount,
            lockTokenAddress: pool.lockTokenAddress,
            prizeTokenAddress: pool.prizeTokenAddress,
            lockTokenSymbol: pool.lockTokenSymbol,
            prizeTokenSymbol: pool.prizeTokenSymbol,
            totalPrizeToken: pool.totalPrizeToken,
            status: pool.status,
            lat: pool.lat,
            long: pool.long,
            zoom: pool.zoom,
            distance: pool.distance,
            callbackMaxTicket: pool.callbackMaxTicket,
            poolTicketLimit: pool.poolTicketLimit
          });
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      //TODO: pinler cluster yapılacak
      function addMultipleBillboards(pool) {
        if (pool.poolId !== 0) {
          viewer.current.entities.add({
            pool,
            position: Cesium.Cartesian3.fromDegrees(
              Number(pool.long),
              Number(pool.lat)
            ),
            billboard: {
              image: RedPin,
              scale: 0.6
            },
            // label: {
            //   text: pool.name,
            // },
          });
        }
      }

      props.pools.forEach((pool) => {
        addMultipleBillboards(pool);
      });

      setLoadingMap(false);
    }

    // ref'i dışa aktar
    ref.current = {
      flyToCity: (latitude, longitude, duration, zoom) => {
        viewer.current && viewer.current.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            longitude,
            latitude,
            zoom
          ),
          duration: duration,
        });
      },
    };

    return () => {
      if (viewer.current) {
        viewer.current.destroy();
      }
    };

  }, [ref, props.poolCount, props.pools, props.myLng, props.myLat]);

  return (
    <div>
      {loadingMap && (
        <h3 style={{textAlign:'center', marginTop: '20%'}}>
          <span className="spinner-border spinner-border"></span>
          <span style={{marginLeft: '5px'}}>LOADING MAP & POOLS</span>
        </h3>
      )}
      <div
        className={"cmap"}
        ref={cesiumContainer}
        style={{ width: "115%", height: "89.5vh" }}
      />
    </div>
  );
});

export default CesiumMap;
