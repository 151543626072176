import React, { useState, useEffect, useRef } from "react";
import { CloseButton } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  Contract,
  encodeBytes32String,
  decodeBytes32String,
  formatEther,
} from "ethers";
import { useWalletConnection } from "../../utility/useWalletConnection";
import { useData } from "../../utility/useData";
import ConnectButton from "../connect-button/ConnectButton";
import IERC20 from "../../abis/IERC20.json";
import UserTicketsPopup from "../popups/UserTickets";
import PageLoading from "../popups/PageLoading";
//css
import "./Stake.css";
//images
import Pin from "../../assets/img/pin.png";
import Close from "../../assets/img/closes.png";
import Countdown from "../countdown/Countdown";
import PrizeDetails from "../popups/PrizeDetails";

const Stake = () => {
  const { search } = useLocation();
  const { isConnected, connectedWallet, address, signerVars, unSignerVars } =
    useWalletConnection();
  const {
    decimals,
    initalResultMsg,
    showPoolDetail,
    poolData,
    setReRender,
    togglePoolData,
    openData,
    closeData,
    updatePoolData,
    statusText,
  } = useData();

  const [pageLoading, setPageLoading] = useState(false);
  const [pageLoadingMsg, setPageLoadingMsg] = useState(null);
  const [btnLoading, setBtnLoading] = useState(null);
  const [btnLoadingMsg, setBtnLoadingMsg] = useState(null);
  const [resultMsg, setResultMsg] = useState(initalResultMsg);
  const [contractGetInfo, setContractGetInfo] = useState({
    prizeShareCount: 0,
    userTicketCount: 0,
    userJoinedPoolCount: 0,
    ticketCount: 0,
    assignTicketCount: 0,
  });
  const [userTickets, setUserTickets] = useState([]); //ids
  const [showPrizeDetails, setShowPrizeDetails] = useState(false);
  const [wonTotalPrize, setWonTotalPrize] = useState(0);
  const [totalLock, setTotalLock] = useState(0);
  const [isClaimed, setIsClaimed] = useState(null);

  const joinTicketCountRef = useRef();
  const usedRefCodeRef = useRef();
  const [loadingVals, setLoadingVals] = useState({
    wonPrize : true,
    totalLock : true,
    contractInfo : true,
  })
  
  const getInfo = async () => {
    setLoadingVals(prevState => ({...prevState,
      contractInfo : true,
    }))
    const getInfo = await signerVars.contract.getInfo(poolData.poolId, address);
    const prizeShareCount = Number(getInfo.prizeShareCount);
    const userTicketCount = Number(getInfo.userTicketCount);
    const userJoinedPoolCount = Number(getInfo.userJoinedPoolCount);
    const ticketCount = Number(getInfo.ticketCount);
    const assignTicketCount = Number(getInfo.assignTicketCount);

    console.log({
      prizeShareCount,
      userTicketCount,
      userJoinedPoolCount,
      ticketCount,
      assignTicketCount,
    });


    setContractGetInfo({
      prizeShareCount,
      userTicketCount,
      userJoinedPoolCount,
      ticketCount,
      assignTicketCount,
    });
    setLoadingVals(prevState =>({...prevState,
      contractInfo : false,
    }))
  
    const isClaimed = await signerVars.contract.isClaimed(
      poolData.poolId,
      address
    );
    setIsClaimed(isClaimed);

    const PRECISION_BASIS = Number(await signerVars.contract.PRECISION_BASIS());
    const totalPrizeToken = formatEther(
      await signerVars.contract.totalPrizeToken(poolData.poolId)
    );
    const lpwc = Number(
      await signerVars.contract.lastPrizeWinnerCount(poolData.poolId)
    );
    setLoadingVals( prevState =>({...prevState,
      totalLock : true,
    }))
    const lockedTokens = formatEther(
      await signerVars.contract.lockedTokens(poolData.poolId, address)
    );
    
    setTotalLock(lockedTokens);
    setLoadingVals(prevState => ({...prevState,
      wonPrizes : true,
      totalLock : false
    }));
    let wonPrizes = 0;
    for (let i = 0; i < userTicketCount; i++) {
      const userTicketNum = Number(
        await signerVars.contract.userTickets(poolData.poolId, address, i)
      );
      const drawnTicketUsers = await signerVars.contract.drawnTicketUsers(
        poolData.poolId,
        userTicketNum
      );

      if (drawnTicketUsers.isWon) {
        const wonPrizeIndex = drawnTicketUsers.wonPrizeIndex;
        const prizeShare = Number(
          (await signerVars.contract.poolPrizes(poolData.poolId, wonPrizeIndex))
            .share
        );
        if (Number(drawnTicketUsers.wonPrizeIndex) === prizeShareCount - 1) {
          wonPrizes +=
            (totalPrizeToken * (prizeShare / PRECISION_BASIS)) / lpwc;
        } else {
          wonPrizes += totalPrizeToken * (prizeShare / PRECISION_BASIS);
        }
      }
    }

    setWonTotalPrize(wonPrizes);
    setLoadingVals(prevState =>({...prevState,
      wonPrize : false,
    }));
  };

  const changeTicketCount = (minValue, maxValue) => {
    const ticketCount = joinTicketCountRef.current.value;
    if (ticketCount > 0 && ticketCount <= maxValue) {
      joinTicketCountRef.current.value = ticketCount;
    } else {
      if (ticketCount < minValue) {
        joinTicketCountRef.current.value = minValue;
      }
      if (ticketCount >= maxValue) {
        joinTicketCountRef.current.value = maxValue;
      }
    }
  };

  const getToken = async (tokenAddress, userAddress) => {
    console.log(tokenAddress, userAddress);
    const contract = new Contract(tokenAddress, IERC20.abi, signerVars.signer);
    const balance = await contract.balanceOf(userAddress);
    const allowanceAmount = Number(
      await contract.allowance(address, process.env.REACT_APP_CONTRACT_ADDRESS)
    );

    return { contract, balance, allowanceAmount };
  };

  const joinPool = async (joinValues) => {
    const joinTicketCount = Number(joinTicketCountRef.current.value);
    let refCode = encodeBytes32String("null");
    if (usedRefCodeRef.current.value !== "") {
      refCode = encodeBytes32String(usedRefCodeRef.current.value);
    }
    console.log(refCode);

    const nowTime = Math.floor(Number(new Date().getTime()) / 1000);

    const lockToken = await getToken(
      joinValues.lockTokenAddress,
      address
    ).catch((error) => {
      setResultMsg({
        msgType: 1,
        msg: `(lockToken) ${error.reason || error.message}`,
      });
    });

    if (joinValues.status === 2) {
      const isRefCode = await signerVars.contract.isRefCode(refCode);
      if (
        decodeBytes32String(refCode) === "null" ||
        (decodeBytes32String(refCode) !== "null" && isRefCode)
      ) {
        console.log(nowTime, joinValues.createDate, joinValues.joinDay);
        if (nowTime <= joinValues.createDate + joinValues.joinDay) {
          if (contractGetInfo.assignTicketCount <= joinValues.maxTicketCount) {
            if (
              contractGetInfo.assignTicketCount + joinTicketCount <=
              joinValues.maxTicketCount
            ) {
              if (
                contractGetInfo.userTicketCount + joinTicketCount <=
                joinValues.poolTicketLimit
              ) {
                if (
                  joinTicketCount * Number(joinValues.oneTicketOfToken) <=
                  Number(lockToken.balance)
                ) {
                  if (
                    Number(lockToken.allowanceAmount) <
                    joinTicketCount * Number(joinValues.oneTicketOfToken)
                  ) {
                    setBtnLoadingMsg("Waiting approve...");
                    setBtnLoading(joinValues.buttonName);
                    setPageLoadingMsg(
                      `Please confirm the ${connectedWallet} request`
                    );
                    setPageLoading(true);
                    lockToken.contract
                      .approve(
                        process.env.REACT_APP_CONTRACT_ADDRESS,
                        lockToken.balance
                      )
                      .then(async (result) => {
                        const receipt = await result.wait();
                        setResultMsg({
                          msgType: 2,
                          urlTitle: "view TX",
                          url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                          msg: `Approval`,
                        });
                        setBtnLoadingMsg(null);
                        setBtnLoading(null);
                        console.log("receipt (Approve):", receipt);

                        //Join pool after approval
                        setBtnLoadingMsg("Waiting Join...");
                        setBtnLoading(joinValues.buttonName);
                        setPageLoadingMsg(
                          `Please confirm the ${connectedWallet} request`
                        );
                        setPageLoading(true);
                        const estimateGas = Number(
                          await signerVars.contract.joinPool.estimateGas(
                            joinValues.poolId,
                            joinTicketCount,
                            refCode
                          )
                        );
                        console.log("estimateGas:", estimateGas);
                        signerVars.contract
                          .joinPool(
                            joinValues.poolId,
                            joinTicketCount,
                            refCode,
                            { gasLimit: estimateGas }
                          )
                          .then(async (result) => {
                            const receipt = await result.wait();
                            for (let i = 0; i < receipt.logs.length; i++) {
                              if (receipt.logs[i].eventName === "UserJoined") {
                                console.log(receipt.logs[i]);
                                setResultMsg({
                                  msgType: 2,
                                  urlTitle: "view TX",
                                  url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                                  msg: `You purchased ${
                                    receipt.logs[i].args[2]
                                  } ticket with ${Number(
                                    formatEther(receipt.logs[i].args[3])
                                  ).toFixed(2)} ${
                                    joinValues.lockTokenSymbol
                                  } tokens`,
                                });
                                setBtnLoadingMsg(null);
                                setBtnLoading(null);
                                setPageLoadingMsg(null);
                                setPageLoading(false);
                                setReRender((prevState) => !prevState);
                              }
                            }
                            console.log("receipt (UserJoined):", receipt);
                          })
                          .catch((error) => {
                            setResultMsg({
                              msgType: 1,
                              msg: `(UserJoined) ${
                                error.reason || error.message
                              }`,
                            });
                            console.log("error (UserJoined):", error);
                            setBtnLoadingMsg(null);
                            setBtnLoading(null);
                            setPageLoadingMsg(null);
                            setPageLoading(false);
                          });
                      })
                      .catch((error) => {
                        setResultMsg({
                          msgType: 1,
                          msg: `(Approve) ${error.reason || error.message}`,
                        });
                        console.log("error (Approve):", error);
                        setBtnLoadingMsg(null);
                        setBtnLoading(null);
                        setPageLoadingMsg(null);
                        setPageLoading(false);
                      });
                  } else {
                    setBtnLoadingMsg("Waiting Join...");
                    setBtnLoading(joinValues.buttonName);
                    setPageLoadingMsg(
                      `Please confirm the ${connectedWallet} request`
                    );
                    setPageLoading(true);
                    const estimateGas = Number(
                      await signerVars.contract.joinPool.estimateGas(
                        joinValues.poolId,
                        joinTicketCount,
                        refCode
                      )
                    );
                    console.log("estimateGas:", estimateGas);
                    signerVars.contract
                      .joinPool(joinValues.poolId, joinTicketCount, refCode, {
                        gasLimit: estimateGas,
                      })
                      .then(async (result) => {
                        const receipt = await result.wait();
                        for (let i = 0; i < receipt.logs.length; i++) {
                          if (receipt.logs[i].eventName === "UserJoined") {
                            console.log(receipt.logs[i]);
                            setResultMsg({
                              msgType: 2,
                              urlTitle: "view TX",
                              url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                              msg: `You purchased ${
                                receipt.logs[i].args[2]
                              } ticket with ${Number(
                                formatEther(receipt.logs[i].args[3])
                              ).toFixed(2)} ${
                                joinValues.lockTokenSymbol
                              } tokens`,
                            });
                            setBtnLoadingMsg(null);
                            setBtnLoading(null);
                            setPageLoadingMsg(null);
                            setPageLoading(false);
                            setReRender((prevState) => !prevState);
                          }
                        }
                        console.log("receipt (UserJoined):", receipt);
                      })
                      .catch((error) => {
                        setResultMsg({
                          msgType: 1,
                          msg: `(UserJoined) ${error.reason || error.message}`,
                        });
                        console.log("error (UserJoined):", error);
                        setBtnLoadingMsg(null);
                        setBtnLoading(null);
                        setPageLoadingMsg(null);
                        setPageLoading(false);
                      });
                  }
                } else {
                  setResultMsg({
                    msgType: 1,
                    msg: `Not enough ${joinValues.lockTokenSymbol} token liquidity at your wallet`,
                  });
                }
              } else {
                const availableUserTicket =
                  joinValues.poolTicketLimit - contractGetInfo.userTicketCount;
                if (availableUserTicket >= joinValues.minTicketCount) {
                  setResultMsg({
                    msgType: 1,
                    msg: `${joinTicketCount} maximum amount limit exceeded. Amount available is ${availableUserTicket}`,
                  });
                } else {
                  setResultMsg({
                    msgType: 1,
                    msg: "maximum amount limit exceeded",
                  });
                }
              }
            } else {
              setResultMsg({
                msgType: 1,
                msg: "Ticket count is more than pool max tickets count",
              });
            }
          } else {
            setResultMsg({ msgType: 1, msg: `Pool limit is reached` });
          }
        } else {
          setResultMsg({ msgType: 1, msg: `Join time has ended` });
        }
      } else {
        setResultMsg({ msgType: 1, msg: `Invalid reference code` });
      }
    } else {
      setResultMsg({
        msgType: 1,
        msg: "The selected pool status must be join",
      });
    }
  };

  const getUserTickets = async (poolId, userTicketCount) => {
    let tickets = [];
    for (let i = 0; i < userTicketCount; i++) {
      const userTicketNum = Number(
        await signerVars.contract.userTickets(poolId, address, i)
      );

      tickets.push(userTicketNum);
    }

    if (tickets.length > 0) {
      setUserTickets(tickets);
    } else {
      setResultMsg({
        msgType: 1,
        msg: `You have joined this pools yet`,
      });
    }
  };

  const claimPrize = async (claimValues) => {
    const isClaimed = await signerVars.contract.isClaimed(
      claimValues.poolId,
      address
    );

    const prizeToken = await getToken(
      claimValues.prizeTokenAddress,
      process.env.REACT_APP_CONTRACT_ADDRESS
    ).catch((error) => {
      setResultMsg({
        msgType: 1,
        msg: `(lockToken) ${error.reason || error.message}`,
      });
    });

    console.log(
      poolData.totalPrizeToken,
      wonTotalPrize,
      Number(formatEther(prizeToken.balance))
    );

    if (claimValues.status === 4) {
      if (!isClaimed) {
        if (wonTotalPrize <= Number(formatEther(prizeToken.balance))) {
          setBtnLoadingMsg("Waiting Claim...");
          setBtnLoading(claimValues.buttonName);
          setPageLoadingMsg(`Please confirm the ${connectedWallet} request`);
          setPageLoading(true);
          signerVars.contract
            .claimPrize(claimValues.poolId)
            .then(async (result) => {
              const receipt = await result.wait();
              for (let i = 0; i < receipt.logs.length; i++) {
                if (receipt.logs[i].eventName === "PrizeClaimed") {
                  setResultMsg({
                    msgType: 2,
                    urlTitle: "view TX",
                    url: `${process.env.REACT_APP_NETWORKSCAN}tx/${receipt.hash}`,
                    msg: `You claimed ${Number(
                      Number(receipt.logs[i].args[2]) / decimals
                    ).toFixed(2)} ${
                      claimValues.lockTokenSymbol
                    } tokens and ${Number(
                      Number(receipt.logs[i].args[3]) / decimals
                    ).toFixed(2)} ${claimValues.prizeTokenSymbol} tokens`,
                  });
                  setBtnLoadingMsg(null);
                  setBtnLoading(null);
                  setPageLoadingMsg(null);
                  setPageLoading(false);
                  setIsClaimed(true);
                }
              }
              console.log("receipt (PrizeClaimed):", receipt);
            })
            .catch((error) => {
              setResultMsg({
                msgType: 1,
                msg: `(PrizeClaimed) ${error.reason || error.message}`,
              });
              console.log("error (PrizeClaimed):", error);
              setBtnLoadingMsg(null);
              setBtnLoading(null);
              setPageLoadingMsg(null);
              setPageLoading(false);
            });
        } else {
          setResultMsg({
            msgType: 1,
            msg: `Not enough ${claimValues.prizeTokenSymbol} token liquidity at contract`,
          });
        }
      } else {
        setResultMsg({
          msgType: 1,
          msg: `Already claimed`,
        });
      }
    } else {
      setResultMsg({
        msgType: 1,
        msg: "The selected pool status must be claim",
      });
    }
  };

  useEffect(() => {
    if (isConnected && signerVars.contract && poolData && poolData.poolId > 0) {
      getInfo();
    }
  }, [isConnected, poolData, signerVars, resultMsg]);

  return (
    <>
      {pageLoading && <PageLoading pageLoadingMsg={pageLoadingMsg} />}
      {userTickets.length > 0 && (
        <UserTicketsPopup
          contractGetInfo={contractGetInfo}
          userTickets={userTickets}
          setUserTickets={setUserTickets}
        />
      )}
      {showPrizeDetails && (
        <PrizeDetails onClose={() => setShowPrizeDetails(false)} />
      )}
      <div className="container-box">
        <div
          style={{
            maxHeight: "800px",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <div className="stakeheader">
            <button onClick={togglePoolData} className="close-button">
              <img src={Close} alt="" />
            </button>
            <span>
              <img src={Pin} alt="" /> {poolData.name}
            </span>
            <span className={"span2"}>
              <Countdown
                status={poolData.status}
                startTime={poolData.createDate}
                endTime={poolData.createDate + poolData.joinDay}
              />
              <br />
              time left to join
            </span>
            <span>
              <Countdown
                status={poolData.status}
                startTime={poolData.createDate + poolData.joinDay}
                endTime={
                  poolData.createDate + poolData.joinDay + poolData.lockDay
                }
              />
              <br />
              time left to lock
            </span>
          </div>
          <div className="pool-status">
            Pool Status {statusText(poolData.status)}
          </div>
          <div className="city-images">
            <img src={`./assets/pools/${poolData.img}`} alt={poolData.name} />
          </div>
          <div className="pool-info">
            <div>CITY POOL:</div>
            <div className="placeholder-glow" style={{ margin: 0 }}>
              <span className={!poolData ? "placeholder" : ""}>
                {formatEther(poolData.totalPool)}
              </span>
              <span className={!poolData ? "placeholder" : ""}>
                ${poolData.lockTokenSymbol}{" "}
              </span>
              <p
                className={!poolData ? "placeholder" : ""}
                style={{ textAlign: "end", margin: 0 }}
              >
                ({poolData.maxTicketCount} Ticket)
              </p>
            </div>
            <div>1 TICKET:</div>
            <div
              className="placeholder-glow"
              style={{ textAlign: "end", margin: 0 }}
            >
              <span className={!poolData ? "placeholder" : ""}>
                {formatEther(poolData.oneTicketOfToken)} $
              </span>
              <span className={!poolData ? "placeholder" : ""}>
                {poolData.lockTokenSymbol}
              </span>
            </div>
            <div style={{ margin: 0 }}>
              TOTAL PRIZE:
              <span style={{ display: "block", fontSize: "10pt" }}>
                (
                <Link to={search} onClick={() => setShowPrizeDetails(true)}>
                  Prize Details
                </Link>
                )
              </span>
            </div>
            <div style={{ textAlign: "end", margin: 0 }}>
              {formatEther(poolData.totalPrizeToken)} $
              {poolData.prizeTokenSymbol}
            </div>
          </div>
          <div className={"minamount"}>
            The minimum lock amount is {poolData.minTicketCount} ticket (
            {formatEther(poolData.oneTicketOfToken)} ${poolData.lockTokenSymbol}
            )
          </div>
          <div className="container-box-bottom">
            {!isConnected ? (
              <div className="d-grid col-5 mx-auto connect-button-field">
                <ConnectButton />
              </div>
            ) : (
              <>
                {resultMsg.msgType > 0 && (
                  <p
                    className={
                      resultMsg.msgType === 1 ? "error-msg" : "success-msg"
                    }
                    style={{ maxWidth: "400px", overflowWrap: "break-word" }}
                  >
                    {resultMsg.msgType === 1
                      ? `Error: ${resultMsg.msg} `
                      : `Success: ${resultMsg.msg} `}
                    {resultMsg.url && (
                      <Link
                        to={resultMsg.url}
                        target="_blank"
                        className="padding-0-1"
                      >
                        {resultMsg.urlTitle}
                      </Link>
                    )}
                  </p>
                )}
                {/* Deactive Screen */}
                {poolData.status === 1 && (
                  <>
                    <h2
                      style={{ border: "1px solid #000", textAlign: "center" }}
                    >
                      Deactive Screen
                    </h2>
                  </>
                )}
                {/* Join Screen */}
                {poolData.status === 2 && (
                  <>
                    {/* TODO: bağlı cüzdanın lock token balance ı gösterilecek */}
                    <div className="token-amount" style={{ marginBottom: "0" }}>
                      Your {poolData.lockTokenSymbol} Balance: ...
                    </div>
                    <div className="token-amount">
                      <input
                        ref={joinTicketCountRef}
                        onChange={(e) =>
                          changeTicketCount(
                            poolData.minTicketCount,
                            poolData.callbackMaxTicket
                          )
                        }
                        type="number"
                        min={1}
                        max={poolData.callbackMaxTicket}
                        placeholder={`Ticket Count (between ${poolData.minTicketCount} and ${poolData.callbackMaxTicket})`}
                      />
                    </div>
                    <div className="token-amount">
                      <input
                        ref={usedRefCodeRef}
                        placeholder="Ref Code (optional)"
                        style={{ display: "inline-block" }}
                      />
                    </div>
                    <div className="btns-area">
                      <button
                        className={
                          btnLoading && btnLoading === "Join Pool"
                            ? "w100 stake-button btn-disabled"
                            : "w100 stake-button"
                        }
                        onClick={() =>
                          joinPool({
                            buttonName: "Join Pool",
                            poolId: poolData.poolId,
                            minTicketCount: poolData.minTicketCount,
                            maxTicketCount: poolData.maxTicketCount,
                            lockTokenAddress: poolData.lockTokenAddress,
                            lockTokenSymbol: poolData.lockTokenSymbol,
                            status: poolData.status,
                            oneTicketOfToken: poolData.oneTicketOfToken,
                            createDate: poolData.createDate,
                            joinDay: poolData.joinDay,
                            poolTicketLimit: poolData.poolTicketLimit,
                          })
                        }
                      >
                        {btnLoading && btnLoading === "Join Pool" ? (
                          <>
                            <span className="spinner-border spinner-border-sm"></span>
                            {` ${btnLoadingMsg}`}
                          </>
                        ) : (
                          "Join Pool"
                        )}
                      </button>
                    </div>
                  </>
                )}
                {/* Lock Screen */}
                {poolData.status === 3 && (
                  <>
                    <h2
                      style={{ border: "1px solid #000", textAlign: "center" }}
                    >
                      <Countdown
                        status={poolData.status}
                        startTime={poolData.createDate + poolData.joinDay}
                        endTime={
                          poolData.createDate +
                          poolData.joinDay +
                          poolData.lockDay
                        }
                      />
                    </h2>
                  </>
                )}
                {/* Claim Screen */}
                {poolData.status === 4 && (
                  <>
                    <h2 className="placeholder-glow" style={{ textAlign: "center", color: "green" }}>
                      <span className={!loadingVals.wonPrize? "" : "placeholder"}>
                      {
                        loadingVals.wonPrize? "Loading" : 
                      `${Number(wonTotalPrize).toFixed(2)} $${
                        poolData.prizeTokenSymbol
                      }`}
                      </span>
                     
                    </h2>
                    <h3 className="placeholder-glow"  style={{ textAlign: "center", color: "green" }}>
                      <span className={!loadingVals.totalLock? "" : "placeholder"}>
                      {
                         loadingVals.totalLock? "Loading" : 
                      `${Number(totalLock).toFixed(2)} $${
                        poolData.lockTokenSymbol
                      }`}
                      </span>
                    </h3>
                    {isClaimed && (
                      <h5 style={{ textAlign: "center", color: "green" }}>
                        Claimed tokens
                      </h5>
                    )}
                    <div className="btns-area">
                      <button
                        className={
                          btnLoading && btnLoading === "Claim"
                            ? "w100 stake-button btn-disabled"
                            : "w100 stake-button"
                        }
                        onClick={() =>
                          claimPrize({
                            buttonName: "Claim",
                            poolId: poolData.poolId,
                            status: poolData.status,
                            lockTokenAddress: poolData.lockTokenAddress,
                            lockTokenSymbol: poolData.lockTokenSymbol,
                            prizeTokenAddress: poolData.prizeTokenAddress,
                            prizeTokenSymbol: poolData.prizeTokenSymbol,
                          })
                        }
                      >
                        {btnLoading && btnLoading === "Claim" ? (
                          <>
                            <span className="spinner-border spinner-border-sm"></span>
                            {` ${btnLoadingMsg}`}
                          </>
                        ) : (
                          "Claim"
                        )}
                      </button>
                    </div>
                  </>
                )}
                {/* Ended Screen */}
                {poolData.status === 5 && (
                  <>
                    <h2
                      style={{ border: "1px solid #000", textAlign: "center" }}
                    >
                      Ended Screen
                    </h2>
                  </>
                )}

                <div className="footer">
                  <div>
                    <span>
                      My ticket Count (
                      <Link
                        to={search}
                        onClick={() =>
                          getUserTickets(
                            poolData.poolId,
                            contractGetInfo.userTicketCount
                          )
                        }
                      >
                        view Tickets
                      </Link>
                      ):
                    </span>
                    <p className="placeholder-glow">
                      <small
                        className={
                          loadingVals.contractInfo ? "placeholder" : ""
                        }
                      >
                        {" "}
                        {
                           contractGetInfo.userTicketCount}
                      </small>
                    </p>
                  </div>

                  <div  className="placeholder-glow">
                    <span  className={loadingVals.totalLock ? "placeholder" : ""}>
                      Tokens I have Locked ({poolData.lockTokenSymbol}):
                    </span>
                    <p>
                      <small className={loadingVals.totalLock ? "placeholder" : ""}>
                        {" "}
                        {loadingVals.totalLock 
                          ? "Loading"
                          : Number(totalLock).toFixed(2)}{" "}
                      </small>
                    </p>
                  </div>

                  <div className="placeholder-glow">
                    <p>
                      My prize tokens
                      <span className={poolData ? "" : "placeholder"}>
                        {" "}
                        ({poolData.prizeTokenSymbol}):
                      </span>
                    
                    </p>
                    <small className={!wonTotalPrize ? "" : "placeholder"}>
                      {Number(wonTotalPrize).toFixed(2)}
                    </small>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.forwardRef(Stake);
