import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWalletConnection } from "../../utility/useWalletConnection";
import { useData } from "../../utility/useData";
import ConnectButton from "../connect-button/ConnectButton";
import { Contract } from "ethers";
import IERC20 from "../../abis/IERC20.json";
import PoolListFilter from "../pool-list-filter/PoolListFilter";
//img
import filterHide from "../../assets/img/filter-50-hide.png";
import filterShow from "../../assets/img/filter-50-show.png";

//TODO: my joined pool kısmında poola katılma anından sonra pool bu alan render olmalı
const LeftMenu = ({
  myLat,
  myLng,
  defDistance,
  handleFlyToCity,
  poolCount,
  setPoolCount,
  pools,
  setPools,
}) => {
  const { search } = useLocation();

  const { isConnected, address, signerVars, unSignerVars } =
    useWalletConnection();

  const {
    reRender,
    decimals,
    initalResultMsg,
    showPoolDetail,
    poolData,
    togglePoolData,
    openData,
    closeData,
    updatePoolData,
    statusText,
  } = useData();

  const [isLoading, setIsLoading] = useState(true);

  const menuMyPoolStatus = sessionStorage.getItem("menu-my-pool");
  const menuAllPoolStatus = sessionStorage.getItem("menu-all-pool");
  const [searchResult, setSearchResult] = useState([]);
  const [menuMyPools, setMenuMyPools] = useState(
    menuMyPoolStatus ? JSON.parse(menuMyPoolStatus) : true
  );
  const [menuAllPools, setMenuAllPools] = useState(
    menuAllPoolStatus ? JSON.parse(menuAllPoolStatus) : true
  );

  const [userJoinedPools, setUserJoinedPools] = useState([]);

  const [filteredAllPools, setFilteredAllPools] = useState([]);
  const [filteredMyPools, setFilteredMyPools] = useState([]);

  const [visibleFilter, setVisibleFilter] = useState({
    myPoolFilter: false,
    allPoolFilter: false,
    notJoinedPoolFilter: false,
  });

  const toggleFilter = (filterId) => {
    setVisibleFilter((prevState) => ({
      ...prevState,
      [filterId]: !prevState[filterId],
    }));
  };

  const searchVesmap = useRef();

  const toggleMenuItem = (menu) => {
    if (menu === "mypool") {
      setMenuMyPools((prevState) => !prevState);
      sessionStorage.setItem("menu-my-pool", !menuMyPools);
    }
    if (menu === "allpool") {
      setMenuAllPools((prevState) => !prevState);
      sessionStorage.setItem("menu-all-pool", !menuAllPools);
    }
  };

  const searchCity = () => {
    let word = searchVesmap.current.value.toLowerCase();
    if (word.length > 0) {
      let result = pools.filter((f) => f.name.toLowerCase().includes(word));
      setSearchResult(result);
    } else {
      setSearchResult([]);
    }
  };

  const getPoolCount = async () => {
    const poolCounter = Number(await unSignerVars.contract.poolCounter());
    console.log(">>> Get poolCounter", poolCounter);
    setPoolCount(poolCounter);
  };

  const getToken = async (tokenAddress) => {
    const contract = new Contract(
      tokenAddress,
      IERC20.abi,
      unSignerVars.provider
    );
    const symbol = await contract.symbol();
    const name = await contract.name();
    const decimals = await contract.decimals();

    return { contract, symbol, name, decimals };
  };

  const getPools = async () => {
    let pools = [];
    const callbackMaxTicket = Number(
      await unSignerVars.contract.callbackMaxTicket()
    );
    for (let poolId = 1; poolId <= poolCount; poolId++) {
      const pool = await unSignerVars.contract.pools(poolId);
      const maxTicketCount = Number(
        (
          await unSignerVars.contract.getInfo(
            poolId,
            address || "0x0000000000000000000000000000000000000000"
          )
        ).maxTicketCount
      );
      const lockTokenAddress = await unSignerVars.contract.lockToken(poolId);
      const prizeTokenAddress = await unSignerVars.contract.prizeToken(poolId);
      const totalPrizeToken = await unSignerVars.contract.totalPrizeToken(
        poolId
      );
      const poolTicketLimit = Number(
        await unSignerVars.contract.poolTicketLimit(poolId)
      );
      const lockToken = await getToken(lockTokenAddress).catch((error) => {
        console.log(`(lockToken) ${error.reason || error.message}`);
      });
      const prizeToken = await getToken(prizeTokenAddress).catch((error) => {
        console.log(`(prizeToken) ${error.reason || error.message}`);
      });

      const poolObj = {
        poolId,
        name: pool.name,
        img: `${pool.name.toLowerCase().replace(" ", "-")}.jpg`,
        createDate: Number(pool.createDate),
        joinDay: Number(pool.joinDay),
        lockDay: Number(pool.lockDay),
        totalPool: pool.totalPool,
        oneTicketOfToken: pool.oneTicketOfToken,
        maxTicketCount,
        minTicketCount: 1,
        lockTokenAddress,
        prizeTokenAddress,
        lockTokenSymbol: lockToken && lockToken.symbol,
        prizeTokenSymbol: prizeToken && prizeToken.symbol,
        totalPrizeToken,
        status: Number(pool.status),
        lat: pool.lat,
        long: pool.long,
        zoom: 2,
        distance: 500000,
        callbackMaxTicket,
        poolTicketLimit,
      };
      pools.push(poolObj);
    }

    pools.reverse(function (a, b) {
      return a.poolId - b.poolId;
    });
    console.log(pools);
    setPools(pools);

    if (isConnected && address && signerVars.contract && pools.length > 0) {
      getUserJoinedPools(pools);
    }
  };

  const getUserJoinedPools = async (allPools) => {
    const getInfo = await signerVars.contract.getInfo(1, address);
    const userJoinedPoolCount = Number(getInfo.userJoinedPoolCount);
    let userPools = [];

    for (let i = 0; i < userJoinedPoolCount; i++) {
      const joinedPoolId = Number(
        await signerVars.contract.joinedPools(address, i)
      );
      allPools.forEach((pool, index) => {
        if (pool.poolId === joinedPoolId) {
          userPools.push(allPools[index]);
        }
      });
    }

    console.log("userPools:", userPools);
    setUserJoinedPools(userPools);
    setFilteredMyPools(userPools);
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      if (unSignerVars.contract) {
        getPoolCount();

        if (poolCount > 0) {
          getPools();
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [unSignerVars, signerVars, poolCount, isConnected, address]);

  useEffect(() => {
    if (signerVars.contract && pools.length > 0) {
      getUserJoinedPools(pools);
      setFilteredAllPools(pools);
      setFilteredMyPools(userJoinedPools);
    }
    
    setIsLoading(false);
  }, [pools, signerVars, reRender]);

  // Contract Events
  useEffect(() => {
    if (unSignerVars.contract) {
      unSignerVars.contract.on("PoolCreated", async (poolId) => {
        console.log("#PoolCreated event was emmited");
        if (poolCount !== Number(poolId)) {
          setPoolCount(Number(poolId));
          console.log(">>> updated poolCount", Number(poolId));
        }
      });
    }
  }, [unSignerVars, poolCount, reRender]);

  return (
    <div className={"sidearaea"}>
      <div className="search-item">
        <input
          type="text"
          className="search-in"
          placeholder="Search in Vesmap"
          ref={searchVesmap}
          onChange={() => searchCity()}
        />
      </div>
      {searchResult.length > 0 && (
        <div className="search-list">
          {searchResult.map((pool) => (
            <Link
              key={pool.poolId}
              to={search}
              onClick={() => {
                handleFlyToCity({
                  poolId: pool.poolId,
                  name: pool.name,
                  img: pool.img,
                  createDate: pool.createDate,
                  joinDay: pool.joinDay,
                  lockDay: pool.lockDay,
                  totalPool: pool.totalPool,
                  oneTicketOfToken: pool.oneTicketOfToken,
                  maxTicketCount: pool.maxTicketCount,
                  minTicketCount: pool.minTicketCount,
                  lockTokenAddress: pool.lockTokenAddress,
                  prizeTokenAddress: pool.prizeTokenAddress,
                  lockTokenSymbol: pool.lockTokenSymbol,
                  prizeTokenSymbol: pool.prizeTokenSymbol,
                  totalPrizeToken: pool.totalPrizeToken,
                  status: pool.status,
                  lat: pool.lat,
                  long: pool.long,
                  zoom: pool.zoom,
                  distance: pool.distance,
                });
                setSearchResult([]);
                searchVesmap.current.value = "";
              }}
            >
              {pool.name} ({statusText(pool.status)})
            </Link>
          ))}
        </div>
      )}
      <div className="list-area">
        <div className="d-flex justify-content-between">
          <div
            className={menuMyPools ? "title-list" : "title-list-hide"}
            onClick={() => toggleMenuItem("mypool")}
          >
            My Joined Pools
          </div>
          <div>
            <button
              onClick={() => {
                toggleFilter("myPoolFilter");
              }}
              className="border-0 bg-transparent text-dark font-italic "
            >
              <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                <img
                  src={visibleFilter.myPoolFilter ? filterHide : filterShow}
                  alt="filter"
                  style={{ width: "24px" }}
                />
              </span>
            </button>
          </div>
        </div>

        {menuMyPools && (
          <>
            {visibleFilter.myPoolFilter && (
              <PoolListFilter
                pools={userJoinedPools}
                onFilterChange={setFilteredMyPools}
                uniqueKey={"MyPools"}
              />
            )}
          </>
        )}

        {menuMyPools && (
          <div className="staking-list">
            {!isConnected ? (
              <div className="d-grid mx-auto">
                <ConnectButton />
              </div>
            ) : isLoading ? (
              <span>Loading</span>
            ) : userJoinedPools.length > 0 ? (
              (console.log(userJoinedPools),
              // TODO : userJoinedPools yerine filteredMyPools kullanıldı.
              // userJoinedPools.map((pool) => (
              //   <Link
              //     key={pool.poolId}
              //     to={search}
              //     onClick={() =>
              //       handleFlyToCity({
              //         poolId: pool.poolId,
              //         name: pool.name,
              //         img: pool.img,
              //         createDate: pool.createDate,
              //         joinDay: pool.joinDay,
              //         lockDay: pool.lockDay,
              //         totalPool: pool.totalPool,
              //         oneTicketOfToken: pool.oneTicketOfToken,
              //         maxTicketCount: pool.maxTicketCount,
              //         minTicketCount: pool.minTicketCount,
              //         lockTokenAddress: pool.lockTokenAddress,
              //         prizeTokenAddress: pool.prizeTokenAddress,
              //         lockTokenSymbol: pool.lockTokenSymbol,
              //         prizeTokenSymbol: pool.prizeTokenSymbol,
              //         totalPrizeToken: pool.totalPrizeToken,
              //         status: pool.status,
              //         lat: pool.lat,
              //         long: pool.long,
              //         zoom: pool.zoom,
              //         distance: pool.distance,
              //         callbackMaxTicket: pool.callbackMaxTicket,
              //         poolTicketLimit: pool.poolTicketLimit,
              //       })
              //     }
              //   >
              //     {pool.name}{" "}
              //     <span
              //       style={
              //         pool.status === 1
              //           ? { color: "red" }
              //           : pool.status === 6
              //             ? { color: "green" }
              //             : null
              //       }
              //     >
              //       ({statusText(pool.status)})
              //     </span>
              //   </Link>
              // ))

              filteredMyPools.map((pool) => (
                <Link
                  key={pool.poolId}
                  to={search}
                  onClick={() => handleFlyToCity(pool)}
                >
                  {pool.name}{" "}
                  <span
                    style={{
                      color:
                        pool.status === 1
                          ? "red"
                          : pool.status === 6
                          ? "green"
                          : null,
                    }}
                  >
                    ({statusText(pool.status)})
                  </span>
                </Link>
              )))
            ) : (
              "You have not joined any pools yet"
            )}
          </div>
        )}

        <div className="d-flex justify-content-between">
          <div
            className={menuAllPools ? "title-list" : "title-list-hide"}
            onClick={() => toggleMenuItem("allpool")}
          >
            All Pools
          </div>
          <div>
            <button
              onClick={() => {
                toggleFilter("allPoolFilter");
              }}
              className="border-0 bg-transparent text-dark"
            >
              <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                <img
                  src={visibleFilter.allPoolFilter ? filterHide : filterShow}
                  alt="filter"
                  style={{ width: "24px" }}
                />
              </span>
            </button>
          </div>
        </div>

        {menuAllPools && (
          <>
            {visibleFilter.allPoolFilter && (
              <PoolListFilter
                pools={pools}
                onFilterChange={setFilteredAllPools}
                uniqueKey={"All"}
              />
            )}
          </>
        )}

        {isLoading ? (
          <>
            <span className="spinner-border spinner-border-sm"></span>
            <span> Loading all pools...</span>
          </>
        ) : (
          menuAllPools && (
            <div className="staking-list">
              {pools.length > 0 &&
                filteredAllPools.map((pool) => (
                  <Link
                    key={pool.poolId}
                    to={search}
                    onClick={() =>
                      handleFlyToCity({
                        poolId: pool.poolId,
                        name: pool.name,
                        img: pool.img,
                        createDate: pool.createDate,
                        joinDay: pool.joinDay,
                        lockDay: pool.lockDay,
                        totalPool: pool.totalPool,
                        oneTicketOfToken: pool.oneTicketOfToken,
                        maxTicketCount: pool.maxTicketCount,
                        minTicketCount: pool.minTicketCount,
                        lockTokenAddress: pool.lockTokenAddress,
                        prizeTokenAddress: pool.prizeTokenAddress,
                        lockTokenSymbol: pool.lockTokenSymbol,
                        prizeTokenSymbol: pool.prizeTokenSymbol,
                        totalPrizeToken: pool.totalPrizeToken,
                        status: pool.status,
                        lat: pool.lat,
                        long: pool.long,
                        zoom: pool.zoom,
                        distance: pool.distance,
                        callbackMaxTicket: pool.callbackMaxTicket,
                        poolTicketLimit: pool.poolTicketLimit,
                      })
                    }
                  >
                    {pool.name}{" "}
                    <span
                      style={
                        pool.status === 1
                          ? { color: "red" }
                          : pool.status === 6
                          ? { color: "green" }
                          : null
                      }
                    >
                      ({statusText(pool.status)})
                    </span>
                  </Link>
                ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default LeftMenu;
